import axios from "axios";
import { app } from "../context/AuthContext";

const mongoApi = axios.create({
  baseURL: process.env.REACT_APP_MONGODB_BASE_URL,
});

const getAuthConfig = async () => {
  if (!app.currentUser) {
    return null;
  }

  await app.currentUser.refreshAccessToken();
  const accessToken = app.currentUser.accessToken;

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

//Using serverless with token authentication.  This updates a problem with token authentication. Tested.
export const updateProblem = async (updatedProblem) => {
  const config = await getAuthConfig();
  const res = await mongoApi.post("updateproblem", updatedProblem, config);
  return res.data;
};

//Using serverless with token authentication. Tested.
export const createProblem = async (updatedProblem) => {
  const config = await getAuthConfig();
  const res = await mongoApi.post("createproblem", updatedProblem, config);
  return res.data;
};

//Using serverless with token authentication. Tested.
export const getProblemTypes = async () => {
  const config = await getAuthConfig();
  const response = await mongoApi.get("getdistinctproblemtypes", config);
  return response.data.body;
};

//Using serverless with token authentication. Tested.
export const getMockGenres = async () => {
  const config = await getAuthConfig();
  const response = await mongoApi.get("getdistinctmockgenres", config);
  return response.data.body;
};

//Using serverless with token authentication. Tested.
export const getBlackListedTopicsAndGenres = async () => {
  const config = await getAuthConfig();
  const response = await mongoApi.get("getblacklistedtopicsandgenre", config);
  return response.data.body;
};

//Using serverless with token authentication. Tested.
export const getGenres = async () => {
  const config = await getAuthConfig();
  const response = await mongoApi.get("getgenres", config);
  return response.data.body;
};

//Using serverless with token authentication. Tested.
export const getProblemTypesInCategories = async () => {
  const config = await getAuthConfig();
  const response = await mongoApi.get("gettypesincategories", config);
  return response.data.body;
};

//Using serverless with token authentication. Tested.
export const getDistinctTestsByResource = async (testResource) => {
  if (testResource) {
    const config = await getAuthConfig();
    const res = await mongoApi.get("readtestsbyresource", {
      params: {
        testResource: testResource,
      },
      ...config,
    });
    return res.data;
  }
};

//Using serverless with token authentication. Tested.
export const getAllDistinctTests = async () => {
  const config = await getAuthConfig();
  const res = await mongoApi.get("getdistincttests", config);
  return res.data.body;
};

//Using serverless with token authentication. Tested.
export const getAllDistinctTestResources = async () => {
  const config = await getAuthConfig();
  const res = await mongoApi.get("getdistincttestresources", config);
  return res.data.body;
};

//Using serverless with token authentication. Tested.
export const getTestListByTestResource = async (testResource) => {
  const config = await getAuthConfig();
  const res = await mongoApi.get("gettestsbytestresource", {
    params: {
      testResource: testResource,
    },
    ...config,
  });
  return res.data.body;
};

//Using serverless with token authentication. Tested.
export const getTestAnswerSheet = async (testName) => {
  const config = await getAuthConfig();
  const res = await mongoApi.get("gettestanswersheet", {
    params: {
      testName: testName,
    },
    ...config,
  });
  return res.data.body;
};

//Using serverless with token authentication. Tested.
export const getProblemsByType = async (selectedProblemType) => {
  const config = await getAuthConfig();
  const res = await mongoApi.get("getproblemsbytype", {
    params: {
      problemType: selectedProblemType,
    },
    ...config,
  });
  return res.data.body;
};

//Using serverless with token authentication. Tested.
export const getProblemsByTest = async (testName) => {
  const config = await getAuthConfig();
  const res = await mongoApi.get("getproblemsbytest", {
    params: {
      testName: testName,
    },
    ...config,
  });
  return res.data.body;
};

//Using serverless with token authentication. Tested.
export const getProblemById = async (problemId) => {
  const config = await getAuthConfig();
  const res = await mongoApi.get("getproblembyid", {
    params: {
      problemId: problemId,
    },
    ...config,
  });
  return res.data.body;
};

//Using serverless with token authentication. Tested.
export const getAnswersForArrayOfQuestionObjects = async (
  arrayOfProblemObjects
) => {
  const config = await getAuthConfig();
  const res = await mongoApi.post(
    "getanswersforarrayofquestionobjects",
    { problems: arrayOfProblemObjects },
    {
      headers: { "Content-Type": "application/json" },
      ...config,
    }
  );
  return res.data;
};

//Using serverless with token authentication. Tested.
export const getNumberOfSectionsByTest = async (testName) => {
  const config = await getAuthConfig();
  const res = await mongoApi.get("getnumberofsectionsbytest", {
    params: {
      testName: testName,
    },
    ...config,
  });
  return res.data.body;
};

//Using serverless with token authentication. Tested.
export const getQuestionsByTestAndSection = async (testName, sectionNumber) => {
  const config = await getAuthConfig();
  const res = await mongoApi.get("getquestionsbytestandsection", {
    params: {
      testName: testName,
      sectionNumber: sectionNumber,
    },
    ...config,
  });
  return res.data.body;
};

//Using serverless with token authentication. Tested.
export const getProblemBySectionAndNumber = async (
  testName,
  sectionNumber,
  problemNumber
) => {
  const config = await getAuthConfig();
  const res = await mongoApi.get("getproblembysectionandnumber", {
    params: {
      testName: testName,
      sectionNumber: sectionNumber,
      problemNumber: problemNumber,
    },
    ...config,
  });
  return res.data.body[0];
};

//Using serverless with token authentication. Tested.
export const searchOriginalProblemText = async (problemText) => {
  const config = await getAuthConfig();
  const res = await mongoApi.get("searchoriginalproblemtext", {
    params: {
      problemText: problemText,
    },
    ...config,
  });
  return res.data.body;
};

//Using serverless with token authentication.
export const searchMockProblemText = async (problemText) => {
  const config = await getAuthConfig();
  const res = await mongoApi.get("searchmockproblemtext", {
    params: {
      problemText: problemText,
    },
    ...config,
  });
  return res.data.body;
};

//takes in a resource ("Digital SAT", "Official ACT", etc...) and a type ("reading", "math", "writing", "science", "criticalReasoning") and returns an array of objects with the problemType and the percent of all questions that are that type.
export const getProblemTypesByIncidence = async (resource, type) => {
  const config = await getAuthConfig();
  const res = await mongoApi.get("getproblemtypesbyincidence", {
    params: {
      resource: resource,
      type: type,
    },
    ...config,
  });
  return res.data.body;
};

//Using serverless with token authentication. Tested.
export const determineWhichDigitalTests = async (problemId) => {
  const config = await getAuthConfig();
  const res = await mongoApi.get("determinewhichdigitaltests", {
    params: {
      problemId: problemId,
    },
    ...config,
  });
  return res.data;
};

//Using serverless with token authentication. Tested.
export const getListOfQuestionsWithExplanations = async () => {
  const config = await getAuthConfig();
  const res = await mongoApi.get("getlistofquestionswithexplanations", {
    ...config,
  });
  return res.data.problemIds;
};

export const getAllCollegeBoardVocabWords = async () => {
  const config = await getAuthConfig();
  const response = await mongoApi.get("getallcollegeboardvocabwords", config);
  return JSON.parse(response.data.body);
};

export const getAllResolveVocabWords = async () => {
  const config = await getAuthConfig();
  const response = await mongoApi.get("getallresolvevocabwords", config);
  return JSON.parse(response.data.body);
};

export const getPercentageRightForSpecificDigitalSatQuestion = async (
  problemId
) => {
  //console.log("get percentage right for specific digital sat question called.");
  const config = await getAuthConfig();
  const res = await mongoApi.get(
    "getPercentageRightForSpecificDigitalSatQuestion",
    {
      params: {
        problemId: problemId,
      },
      ...config,
    }
  );
  //console.log("here is the result from the server.");
  //console.log(res.data);
  return res.data;
};

export const getPercentageRightForSpecificWorksheetQuestion = async (
  problemId
) => {
  //console.log("get percentage right for specific digital sat question called.");
  const config = await getAuthConfig();
  const res = await mongoApi.get(
    "getPercentageRightForSpecificWorksheetQuestion",
    {
      params: {
        problemId: problemId,
      },
      ...config,
    }
  );
  //console.log("here is the result from the server.");
  //console.log(res.data);
  return res.data;
};
