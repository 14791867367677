import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import AgendaItemCard from "./AgendaItemCard";
import "./UserSignedInCard.css";
import { useQuery } from "react-query";
import { getDetailedUser } from "../../api/userApi";
import useTotalSATTimeRemaining from "../../hooks/useTotalSATTimeRemaining";
import useTotalTimeForPracticeModule from "../../hooks/useTotalTimeForPracticeModule";
import useMostRecentTests from "../../hooks/useMostRecentTests";
import useProblemTypes from "../../hooks/useProblemTypes";
import { getAllTests } from "../../api/digitalSATApi";
import { getProblemTypesByIncidence } from "../../api/problemApi";
import { getAllLearningModules } from "../../api/learningModuleApi";
import { set } from "date-fns";
import math, { filter, i } from "mathjs";
import useRemainingTimeForPracticeModule from "../../hooks/useRemainingTimeForPracticeModule";
import { all } from "axios";
import TooSmallScreenModal from "./TooSmallScreenModal";
import { getSubscriptionDetails } from "../../api/userApi";
import { useUserSubscriptionStatus } from "../../hooks/useUserSubscriptionStatus";
import TutorAssignmentsCard from "./TutorAssignmentsCard";

const UserSignedInCard = ({ collapsed = false, practiceArea = null }) => {
  const navigate = useNavigate();
  const { user } = UserAuth();
  const [firstTestStart, setFirstTestStarted] = useState(false);
  const [firstTestCompleted, setFirstTestCompleted] = useState(false);
  const [anyTestInProgress, setAnyTestInProgress] = useState(false);
  const [activePracticeModules, setActivePracticeModules] = useState(false);
  const [activeLearningModules, setActiveLearningModules] = useState(false);
  const [arrayOfActivePracticeModules, setArrayOfActivePracticeModules] =
    useState([]);
  const [arrayOfActiveLearningModules, setArrayOfActiveLearningModules] =
    useState([]);
  const [arrayOfActiveTests, setArrayOfActiveTests] = useState([]);
  const [smallWindowSize, setSmallWindowSize] = useState(
    window.innerWidth < 768
  ); // 576px is the breakpoint for xs in Bootstrap 5
  const [TooSmallScreenModalShow, setTooSmallScreenModalShow] = useState(false);

  //modules based on test errors
  const [inProgressPracticeMathModules, setInProgressPracticeMathModules] =
    useState([]);
  const [
    inProgressPracticeReadingModules,
    setInProgressPracticeReadingModules,
  ] = useState([]);
  const [
    inProgressPracticeWritingModules,
    setInProgressPracticeWritingModules,
  ] = useState([]);

  const {
    totalTimeRemaining: firstActiveSATTimeRemaining,
    formattedTimeRemaining: formattedFirstActiveSATTimeRemaining,
  } = useTotalSATTimeRemaining(arrayOfActiveTests?.[0]);
  const {
    totalTime: timeInProgressMath,
    formattedTime: formattedTimeInProgressMath,
  } = useTotalTimeForPracticeModule(inProgressPracticeMathModules?.[0]);
  const {
    totalTime: timeInProgressReading,
    formattedTime: formattedTimeInProgressReading,
  } = useTotalTimeForPracticeModule(inProgressPracticeReadingModules?.[0]);
  const {
    totalTime: timeInProgressWriting,
    formattedTime: formattedTimeInProgressWriting,
  } = useTotalTimeForPracticeModule(inProgressPracticeWritingModules?.[0]);

  // Detects the window size and disables buttons to launch modules if the window is too small
  useEffect(() => {
    const handleResize = () => {
      setSmallWindowSize(window.innerWidth < 768);
    };

    // Call the handleResize function on component mount
    // to set the state based on the initial window size
    handleResize();

    // Add the event listener to update the state when the window resizes
    window.addEventListener("resize", handleResize);

    // Remove the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const {
    isLoading: isLoadingDetailedUser,
    refetch: refreshCurrentUser,
    data: detailedUser,
    isSuccess,
  } = useQuery(["detailed-user", user?.email], () =>
    getDetailedUser(user?.email)
  );

  const {
    isLoading: isLoadingAllTests,
    refetch: refreshAllTests,
    data: allDigitalSAT,
    isSuccess: isSuccessAllTests,
    isFetching: isFetchingAllTests,
  } = useQuery("all-digital-sat-tests", () => getAllTests(), {
    refetchOnWindowFocus: false,
    staleTime: Infinity, // Set staleTime to prevent the data from going stale
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: !!user, // Fetch only when the user is available
  });

  const {
    isLoading: isLoadingSubscriptionDetails,
    refetch: refreshSubscriptionDetails,
    data: subscriptionDetails,
    isSuccess: isSuccessSubscriptionDetails,
  } = useQuery(["subscription-details", detailedUser?.subscriptionId], () =>
    getSubscriptionDetails(detailedUser?.subscriptionId)
  );

  const isActiveSubscription = useUserSubscriptionStatus();

  const {
    isLoading: learningModulesLoading,
    refetch: refreshAllLearningModules,
    data: allLearningModules,
    isSuccess: isSuccessAllLearningModules,
    isFetching: isFetchAllLearningModules,
  } = useQuery("all-learning-modules", () => getAllLearningModules(), {
    refetchOnWindowFocus: false,
    staleTime: Infinity, // Set staleTime to prevent the data from going stale
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const {
    isLoading: isLoadingMathTypes,
    refetch: refetchMathTypes,
    data: mathTypes,
    isSuccess: isSuccessMathTypes,
  } = useQuery(
    ["problem-types-priority-math"],
    () => getProblemTypesByIncidence("Digital SAT", "math"),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity, // Set staleTime to prevent the data from going stale
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  // Repeat the above pattern for the remaining queries: "problem-types-priority-reading" and "problem-types-priority-writing"

  const {
    isLoading: isLoadingReadingTypes,
    refetch: refetchReadingTypes,
    data: readingTypes,
    isSuccess: isSuccessReadingTypes,
  } = useQuery(
    ["problem-types-priority-reading"],
    () => getProblemTypesByIncidence("Digital SAT", "reading"),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity, // Set staleTime to prevent the data from going stale
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  const {
    isLoading: isLoadingWritingTypes,
    refetch: refetchWritingTypes,
    data: writingTypes,
    isSuccess: isSuccessWritingTypes,
  } = useQuery(
    ["problem-types-priority-writing"],
    () => getProblemTypesByIncidence("Digital SAT", "writing"),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity, // Set staleTime to prevent the data from going stale
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  //console.log subscriptionDetails
  // useEffect(() => {
  //  console.log(subscriptionDetails);
  // }, [subscriptionDetails])

  // Extract most recent tests from the detailed user
  const mostRecentTests = useMostRecentTests(detailedUser);

  //identify topics that need to be learned.
  const mathWrong = useProblemTypes("Digital SAT", "math", mostRecentTests);
  const readingWrong = useProblemTypes(
    "Digital SAT",
    "reading",
    mostRecentTests
  );
  const writingWrong = useProblemTypes(
    "Digital SAT",
    "writing",
    mostRecentTests
  );
  const completedModules = detailedUser?.practiceModules?.filter(
    (module) => module?.[0].status === "Graded"
  );

  const checkToSeeIfWorksheetAvailable = (problemType) => {
    return allLearningModules?.some(
      (module) =>
        module?.moduleName === problemType &&
        module?.mainDigitalSatWorksheet?.length > 0
    );
  };
  //filter out any mathWrong, readingWrong, or writingWrong that are already in the practiceModules array with a status of "Completed"
  // ...

  const filteredMathWrong = mathWrong?.filter((practiceModule) => {
    const hasWorksheet = checkToSeeIfWorksheetAvailable(
      practiceModule.problemType
    );
    const isCompleted = completedModules?.some(
      (completedModule) =>
        practiceModule.problemType === completedModule[0].problemType
    );
    return hasWorksheet && !isCompleted;
  });

  const filteredReadingWrong = readingWrong?.filter((practiceModule) => {
    const hasWorksheet = checkToSeeIfWorksheetAvailable(
      practiceModule.problemType
    );
    const isCompleted = completedModules?.some(
      (completedModule) =>
        practiceModule.problemType === completedModule[0].problemType
    );
    return hasWorksheet && !isCompleted;
  });

  const filteredWritingWrong = writingWrong?.filter((practiceModule) => {
    const hasWorksheet = checkToSeeIfWorksheetAvailable(
      practiceModule.problemType
    );
    const isCompleted = completedModules?.some(
      (completedModule) =>
        practiceModule.problemType === completedModule[0].problemType
    );
    return hasWorksheet && !isCompleted;
  });

  const firstReadingPracticeModule = allLearningModules?.find(
    (module) => module?.moduleName === filteredReadingWrong?.[0]?.problemType
  );
  const { formattedTime: formattedTimeFirstAvailableReading } =
    useRemainingTimeForPracticeModule(
      firstReadingPracticeModule?.mainDigitalSatWorksheet
    );
  const firstWritingPracticeModule = allLearningModules?.find(
    (module) => module?.moduleName === filteredWritingWrong?.[0]?.problemType
  );
  const { formattedTime: formattedTimeFirstAvailableWriting } =
    useRemainingTimeForPracticeModule(
      firstWritingPracticeModule?.mainDigitalSatWorksheet
    );
  const firstMathPracticeModule = allLearningModules?.find(
    (module) => module?.moduleName === filteredMathWrong?.[0]?.problemType
  );
  const { formattedTime: formattedTimeFirstAvailableMath } =
    useRemainingTimeForPracticeModule(
      firstMathPracticeModule?.mainDigitalSatWorksheet
    );

  //create a useEffect that checks detailedUser.digitalTests array to see if there are any completed tests.
  //Each test[0].testStatus === "Completed" indicates a completed test. if there are any completed tests, setFirstTestCompleted to true and firstTestStarted to true
  //Each test[0].testStatus === "In Progress" indicates a test in progress. if there are any tests in progress, setAnyTestInProgress to true and firstTestStarted to true

  useEffect(() => {
    if (detailedUser) {
      const completedTests = detailedUser?.digitalTests?.filter(
        (test) => test[0].testStatus === "Completed"
      );
      const activeTests = detailedUser?.digitalTests?.filter(
        (test) => test[0].testStatus === "In Progress"
      );

      if (completedTests?.length > 0) {
        setFirstTestCompleted(true);
        setFirstTestStarted(true);
      }
      if (activeTests?.length > 0) {
        setAnyTestInProgress(true);
        setFirstTestStarted(true);
        setArrayOfActiveTests(activeTests);
      }
    }
  }, [isSuccess, detailedUser]);

  //Create a useEffect that checks detailedUser.practiceModules array to see if there are any active modules.
  //Each module[0][0] === "MockAssigned" indicates an active module. if there are any active modules, setActivePracticeModules to true
  useEffect(() => {
    if (isSuccess) {
      const activeModules = detailedUser?.practiceModules?.filter(
        (module) => module?.[0]?.status === "In Progress"
      );

      const tempInProgressMathModules = [];
      const tempInProgressReadingModules = [];
      const tempInProgressWritingModules = [];

      //loop through activeModules and push the problemTypes into the appropriate array
      activeModules?.forEach((module) => {
        if (isProblemTypeOfCategory(module?.[0]?.problemType, mathTypes)) {
          tempInProgressMathModules.push(module);
        }
        if (isProblemTypeOfCategory(module?.[0]?.problemType, readingTypes)) {
          tempInProgressReadingModules.push(module);
        }
        if (isProblemTypeOfCategory(module?.[0]?.problemType, writingTypes)) {
          tempInProgressWritingModules.push(module);
        }
      });
      //set the appropriate state variables to the arrays
      setInProgressPracticeMathModules(tempInProgressMathModules);
      setInProgressPracticeReadingModules(tempInProgressReadingModules);
      setInProgressPracticeWritingModules(tempInProgressWritingModules);

      if (activeModules?.length > 0) {
        setActivePracticeModules(true);
        setArrayOfActivePracticeModules(activeModules);
      }
    }
  }, [isSuccess, detailedUser, mathTypes, readingTypes, writingTypes]);

  //Create a useEffect that checks detailedUser.learningModules array to see if there are any active modules.
  //Each module.status === "Assigned" indicates an active module. if there are any active modules, setActiveLearningModules to true
  useEffect(() => {
    if (isSuccess) {
      const activeModules = detailedUser?.learningModules?.filter(
        (module) => module.status === "Assigned"
      );
      if (activeModules?.length > 0) {
        setActiveLearningModules(true);
        setArrayOfActiveLearningModules(activeModules);
      }
    }
  }, [isSuccess, detailedUser]);

  useEffect(() => {
    if (mathTypes && readingTypes && writingTypes && detailedUser) {
      const tempMathInProgress = detailedUser?.practiceModules?.filter(
        (module) =>
          module?.[0]?.status === "In Progress" &&
          isProblemTypeOfCategory(module[0].problemType, mathTypes)
      );
      const tempReadingInProgress = detailedUser?.practiceModules?.filter(
        (module) =>
          module?.[0]?.status === "In Progress" &&
          isProblemTypeOfCategory(module[0].problemType, readingTypes)
      );
      const tempWritingInProgress = detailedUser?.practiceModules?.filter(
        (module) =>
          module?.[0]?.status === "In Progress" &&
          isProblemTypeOfCategory(module[0].problemType, writingTypes)
      );
      setInProgressPracticeMathModules(tempMathInProgress);
      setInProgressPracticeReadingModules(tempReadingInProgress);
      setInProgressPracticeWritingModules(tempWritingInProgress);
    }
  }, [mathTypes, readingTypes, writingTypes, detailedUser]);

  function assignPriority(category) {
    const noWrongAnswers =
      filteredMathWrong?.length === 0 &&
      filteredReadingWrong?.length === 0 &&
      filteredWritingWrong?.length === 0;

    // Rules for "Full SAT" category
    if (category === "Full SAT") {
      if (anyTestInProgress || !firstTestCompleted || noWrongAnswers) {
        return 1;
      } else {
        return 4; // Lowest priority if any other category has wrong answers
      }
    }

    // Rules for other categories
    let wrongAnswersMap = {
      Writing: filteredWritingWrong?.length,
      Reading: filteredReadingWrong?.length,
      Math: filteredMathWrong?.length,
    };

    // If current category has no wrong answers, assign lowest priority
    if (wrongAnswersMap[category] === 0) {
      return 4;
    }

    // If "Full SAT" has not taken the highest priority, and current category has wrong answers
    if (wrongAnswersMap["Writing"] > 0 && category === "Writing") {
      return 1;
    } else if (wrongAnswersMap["Reading"] > 0 && category === "Reading") {
      return wrongAnswersMap["Writing"] > 0 ? 2 : 1;
    } else if (wrongAnswersMap["Math"] > 0 && category === "Math") {
      if (wrongAnswersMap["Writing"] > 0 && wrongAnswersMap["Reading"] > 0) {
        return 3;
      } else if (
        wrongAnswersMap["Writing"] > 0 ||
        wrongAnswersMap["Reading"] > 0
      ) {
        return 2;
      } else {
        return 1;
      }
    }
  }

  const handleStartFirstSAT = () => {
    if (user) {
      const activeTestNames = detailedUser?.digitalTests?.map((activeTest) =>
        activeTest?.[0]?.testName?.toLowerCase()
      );

      const filteredArray = allDigitalSAT?.filter((test) => {
        const isActiveTest = activeTestNames?.includes(
          test.testName?.toLowerCase()
        );

        return (
          !isActiveTest && !test?.testName?.toLowerCase().includes("bluebook")
        );
      });

      if (filteredArray?.length > 0) {
        navigate("/digitalsat", {
          state: {
            test: filteredArray?.[0],
          },
        });
      } else {
        navigate("/computertests");
      }
    } else {
      navigate("/signup");
    }
  };

  const goToSubscribePage = () => {
    navigate("/subscribe");
  };

  const getAgendaCards = () => {
    const agendaCards = [];

    // Full SAT Card
    agendaCards.push({
      topLeftElement: "Full SAT",
      backgroundColorClass: "bg-color-purple-gradiant",
      title: "Full Practice SAT",
      time: anyTestInProgress
        ? formattedFirstActiveSATTimeRemaining
        : "2 hr, 14 min",
      onClick: !anyTestInProgress
        ? handleStartFirstSAT
        : () => launchFirstTestInActiveTests(),
      agendaItemNumber: assignPriority("Full SAT"),
    });

    if ((!firstTestCompleted || anyTestInProgress) && !smallWindowSize) {
      // Only Full SAT card is displayed
      return agendaCards;
    }

    const filteredMathWrongLength = filteredMathWrong?.length;
    const filteredReadingWrongLength = filteredReadingWrong?.length;
    const filteredWritingWrongLength = filteredWritingWrong?.length;

    // Card for Reading Practice Module
    // if (!smallWindowSize) {
    agendaCards.push({
      topLeftElement: "Reading",
      backgroundColorClass: "bg-color-green-gradiant",
      title:
        inProgressPracticeReadingModules?.length > 0
          ? "Resume " + inProgressPracticeReadingModules?.[0]?.[0]?.problemType
          : filteredReadingWrongLength > 0
          ? `Practice ${filteredReadingWrong?.[0]?.problemType}`
          : "Explore Reading Practice",
      time:
        inProgressPracticeReadingModules?.length > 0
          ? formattedTimeInProgressReading
          : filteredReadingWrongLength > 0
          ? formattedTimeFirstAvailableReading
          : "10 min",
      onClick:
        inProgressPracticeReadingModules?.length > 0
          ? () => resumeFirstActivePracticeModule("Reading")
          : filteredReadingWrongLength > 0
          ? () => launchFirstAvailableModule("Reading")
          : () => {
              navigate("/practicemodules", {
                state: {
                  activeTab: "Reading",
                },
              });
            },
      agendaItemNumber: assignPriority("Reading"),
    });
    // } else {
    //   agendaCards.push({
    //     topLeftElement: "Reading",
    //     backgroundColorClass: "bg-color-green-gradiant",
    //     title: "Explore Reading Practice",
    //     time: "10 min",
    //     onClick: () => setTooSmallScreenModalShow(true),
    //     agendaItemNumber: assignPriority("Reading"),
    //   });
    // }

    // Card for Writing Practice Module
    // if (!smallWindowSize) {
    agendaCards.push({
      topLeftElement: "Writing",
      backgroundColorClass: "bg-color-teal-gradiant",
      title:
        inProgressPracticeWritingModules?.length > 0
          ? "Resume " + inProgressPracticeWritingModules?.[0]?.[0]?.problemType
          : filteredWritingWrongLength > 0
          ? `Practice ${filteredWritingWrong?.[0]?.problemType}`
          : "Explore Writing Practice",
      time:
        inProgressPracticeWritingModules?.length > 0
          ? formattedTimeInProgressWriting
          : filteredWritingWrongLength > 0
          ? formattedTimeFirstAvailableWriting
          : "10 min",
      onClick:
        inProgressPracticeWritingModules?.length > 0
          ? () => resumeFirstActivePracticeModule("Writing")
          : filteredWritingWrongLength > 0
          ? () => launchFirstAvailableModule("Writing")
          : () => {
              navigate("/practicemodules", {
                state: {
                  activeTab: "Writing",
                },
              });
            },
      agendaItemNumber: assignPriority("Writing"),
    });
    // } else {
    //   agendaCards.push({
    //     topLeftElement: "Writing",
    //     backgroundColorClass: "bg-color-teal-gradiant",
    //     title: "Explore Writing Practice",
    //     time: "10 min",
    //     onClick: () => setTooSmallScreenModalShow(true),
    //     agendaItemNumber: assignPriority("Writing"),
    //   });
    // }

    // Card for Math Practice Module
    // if (!smallWindowSize) {
    agendaCards.push({
      topLeftElement: "Math",
      backgroundColorClass: "bg-color-fuscia-gradiant",
      title:
        inProgressPracticeMathModules?.length > 0
          ? "Resume " + inProgressPracticeMathModules?.[0]?.[0]?.problemType
          : filteredMathWrongLength > 0
          ? `Practice ${filteredMathWrong?.[0]?.problemType}`
          : "Explore Math Practice",
      time:
        inProgressPracticeMathModules?.length > 0
          ? formattedTimeInProgressMath
          : filteredMathWrongLength > 0
          ? formattedTimeFirstAvailableMath
          : "10 min",
      onClick:
        inProgressPracticeMathModules?.length > 0
          ? () => resumeFirstActivePracticeModule("Math")
          : filteredMathWrongLength > 0
          ? () => launchFirstAvailableModule("Math")
          : () => {
              navigate("/practicemodules", {
                state: {
                  activeTab: "Math",
                },
              });
            },
      agendaItemNumber: assignPriority("Math"),
    });
    // } else {
    //   agendaCards.push({
    //     topLeftElement: "Math",
    //     backgroundColorClass: "bg-color-fuscia-gradiant",
    //     title: "Explore Math Practice",
    //     time: "10 min",
    //     onClick: () => setTooSmallScreenModalShow(true),
    //     agendaItemNumber: assignPriority("Math"),
    //   });
    // }

    agendaCards.sort((a, b) => a.agendaItemNumber - b.agendaItemNumber);
    return agendaCards;
  };

  const launchFirstTestInActiveTests = () => {
    // if (smallWindowSize) {
    //   setTooSmallScreenModalShow(true);
    // } else {
    const activeTest = arrayOfActiveTests?.[0];
    //find the test object from allDigitalSAT that matches the activeTest
    const test = allDigitalSAT?.find(
      (test) => test.testName === activeTest?.[0]?.testName
    );

    //test represents the test object from allDigitalSAT. activeTest represents the test object from detailedUser.digitalTests
    navigate("/digitalsat", {
      state: {
        test,
        activeTest,
      },
    });
    // }
  };

  const launchFirstAvailableModule = (subject) => {
    // if (smallWindowSize) {
    //   setTooSmallScreenModalShow(true);
    // } else {
    let activeModule;
    if (subject === "Reading") {
      activeModule = filteredReadingWrong?.[0];
    } else if (subject === "Writing") {
      activeModule = filteredWritingWrong?.[0];
    } else if (subject === "Math") {
      activeModule = filteredMathWrong?.[0];
    }
    let foundModule = allLearningModules?.find(
      (module) => module.moduleName === activeModule.problemType
    );

    navigate("/module", {
      state: {
        tempArrayOfQuestions: foundModule?.mainDigitalSatWorksheet,
        arrayOfStudentAnswers: [],
      },
    });
    // }
  };

  const resumeFirstActivePracticeModule = (subject) => {
    // if (smallWindowSize) {
    //   setTooSmallScreenModalShow(true);
    // } else {
    let activeModule;
    if (subject === "Reading") {
      activeModule = inProgressPracticeReadingModules?.[0];
    } else if (subject === "Writing") {
      activeModule = inProgressPracticeWritingModules?.[0];
    } else if (subject === "Math") {
      activeModule = inProgressPracticeMathModules?.[0];
    }
    let foundModule = allLearningModules?.find(
      (module) => module.moduleName === activeModule?.[0]?.problemType
    );

    navigate("/module", {
      state: {
        tempArrayOfQuestions: activeModule?.[0]?.arrayOfQuestions,
        arrayOfStudentAnswers: activeModule,
      },
    });
    // }
  };

  const isProblemTypeOfCategory = (problemType, typesArray) => {
    if (problemType && typesArray) {
      return typesArray.some((type) => type?.questionType === problemType);
    }
  };

  if (
    detailedUser &&
    detailedUser.tutorAssignedWork &&
    (detailedUser.tutorAssignedWork?.practiceTests?.length > 0 ||
      detailedUser.tutorAssignedWork?.practiceTopics?.length > 0 ||
      detailedUser.tutorAssignedWork?.vocabDecks?.length > 0)
  ) {
    return (
      <TutorAssignmentsCard
        detailedUser={detailedUser}
        isActiveSubscription={isActiveSubscription}
        collapsed={collapsed}
        practiceArea={practiceArea}
      />
    );
  }

  return (
    <Card
      className="rounded-4 w-100 bg-color-white overflow-hidden mx-4"
      style={{ maxWidth: "550px" }}
    >
      <Card.Header
        className="border-bottom-0 my-0 pb-0"
        style={{ backgroundColor: "white" }}
      >
        <Row className="d-flex align-middle align-items-center justify-content-center">
          <h2 className="fs-1 p-0 m-0 mb-0 text-center text-black amatic-font font-weight-very-bold">
            {detailedUser?.firstName
              ? `${detailedUser?.firstName}'s To-Do List`
              : "To-Do List"}
          </h2>
        </Row>
      </Card.Header>
      <Card.Body className="my-0 pt-0">
        <Row className="d-flex align-middle align-items-center justify-content-center text-black">
          {getAgendaCards().map((card, index) => (
            <Col
              key={index}
              xs={getAgendaCards().length < 2 ? 12 : 6}
              className={`m-0 p-1${collapsed && index >= 2 ? " d-none" : ""}${
                index >= 2 ? "" : ""
              }`}
            >
              <AgendaItemCard
                backgroundColorClass={card.backgroundColorClass}
                title={card.title}
                time={
                  !isActiveSubscription &&
                  detailedUser?.subscriptionStatus !== "active" &&
                  firstTestCompleted
                    ? "Subscribe to unlock"
                    : card.time
                }
                onClick={
                  !isActiveSubscription &&
                  detailedUser?.subscriptionStatus !== "active" &&
                  firstTestCompleted
                    ? goToSubscribePage
                    : card.onClick
                }
                agendaItemNumber={index + 1}
                topLeftElement={card.topLeftElement}
              />
            </Col>
          ))}
        </Row>
      </Card.Body>
      <TooSmallScreenModal
        show={TooSmallScreenModalShow}
        handleClose={() => setTooSmallScreenModalShow(false)}
      />
    </Card>
  );
};

export default UserSignedInCard;
