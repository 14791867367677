import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';

const AgendaItemCard = ({ backgroundColorClass, title, time, onClick, agendaItemNumber, topLeftElement }) => {
  

    return (
        <Button
        variant="link"
        className="p-0 m-0 text-decoration-none w-100"
        onClick={onClick}
        aria-label={title}
        style={{ height: "8rem"}}
    >
        <Card className={`border-0 rounded-4 bg-color-black text-white text-start add-shadow hover-enlarge d-flex flex-column h-100 overflow-hidden`}>

            <Row className={`${backgroundColorClass} mt-0`}>
                <div className='pt-2'></div>
                <Col xs={4} className='d-sm-none d-md-block m-0 p-0 ps-2 ps-4 my-0'>
                    <div className='fs-6 text-nowrap'>
                        {agendaItemNumber}. <span className='ms-1 d-none d-xl-none d-xxl-inline'>{topLeftElement}</span>
                    </div>
                </Col>
                <Col xs={8} sm={12} md={8} className='text-center text-white d-flex justify-content-end align-items-center pe-4'>
                    <div className='fs-6 text-nowrap'>{time}</div>
                </Col>
            </Row>

            <Row className="flex-grow-1 m-0">
                <Col xs={12} className='text-center align-self-center text-white bg-color-black'>
                    <h3 className='fs-5 pt-2 m-0 d-none d-md-block'>{title}</h3>
                    <h3 className='fs-6 pt-2 m-0 d-md-none'>{title}</h3>
                </Col>
            </Row>
            
            <Card.Footer className="mt-auto border-top-0 bg-color-black m-0" style={{ height: "1.8rem"}}>
            
            </Card.Footer>
            
        </Card>
        </Button>
    )
}

export default AgendaItemCard
