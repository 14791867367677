import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getDetailedUser } from "../../api/userApi";
import { useQuery } from "react-query";
import { UserAuth } from "../../context/AuthContext";
import { useUserSubscriptionStatus } from "../useUserSubscriptionStatus";
import useGetAllTests from "../fetching/useGetAllTests";
import { is } from "date-fns/locale";

const useLaunchTest = (triggerAlert) => {
  const navigate = useNavigate();
  const { user } = UserAuth();

  const {
    isLoading: isLoadingDetailedUser,
    error: detailedUserError,
    data: detailedUser,
    isSuccess,
  } = useQuery(["detailed-user", user?.email], () =>
    getDetailedUser(user?.email)
  );

  const isActiveSubscription = useUserSubscriptionStatus();
  const { allDigitalSat, isLoadingAllDigitalSat, isErrorAllDigitalSat } =
    useGetAllTests();

  // Consider what to do when data is still loading or if there are errors
  if (isLoadingDetailedUser || isLoadingAllDigitalSat) {
    // Optionally handle loading state
  }
  if (detailedUserError || isErrorAllDigitalSat) {
    // Optionally handle errors
  }

  return useCallback(
    (testName) => {
      const testDetails = allDigitalSat?.find(
        (test) => test.testName === testName
      );
      const userTest = detailedUser?.digitalTests?.find(
        (test) =>
          test[0].testName === testName && test[0].testStatus === "In Progress"
      );

      if (!isActiveSubscription && testName !== "Resolve Test 1") {
        triggerAlert?.();
        return;
      }

      navigate("/digitalsat", {
        state: userTest
          ? { test: testDetails, activeTest: userTest }
          : { test: testDetails },
      });
    },
    [navigate, allDigitalSat, detailedUser, isActiveSubscription, triggerAlert]
  );
};

export default useLaunchTest;
