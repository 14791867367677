import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getDetailedUser } from "../../api/userApi";
import { UserAuth } from "../../context/AuthContext";
import { useUserSubscriptionStatus } from "../useUserSubscriptionStatus";

const useLaunchPracticeModule = (triggerAlert) => {
  const navigate = useNavigate();
  const { user } = UserAuth();

  // Assuming you have a hook to fetch practice modules or it's passed down directly.
  const {
    data: detailedUser,
    isLoading: isLoadingDetailedUser,
    error: detailedUserError,
  } = useQuery(["detailed-user-practice-modules", user?.email], () =>
    getDetailedUser(user?.email)
  );

  const isActiveSubscription = useUserSubscriptionStatus();

  return useCallback(
    (topic) => {
      const foundModule = detailedUser?.practiceModules?.find(
        (module) =>
          module[0].problemType === topic.moduleName &&
          module[0].status === "In Progress"
      );

      if (!isActiveSubscription) {
        triggerAlert?.();
        return;
      }

      navigate("/module", {
        state: {
          tempArrayOfQuestions: topic.fullModule?.mainDigitalSatWorksheet,
          arrayOfStudentAnswers: foundModule,
        },
      });
    },
    [navigate, detailedUser, isActiveSubscription, triggerAlert]
  );
};

export default useLaunchPracticeModule;
