import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Image, Container, Button, Offcanvas } from "react-bootstrap";
import {
  faAngleDown,
  faCircleUser,
  faChalkboardTeacher,
  faPenToSquare,
  faAngleUp,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "./media/hand-pencil-large.svg";
import { useNavigate } from "react-router-dom";
import "./StartPageHeader.css";
import { UserAuth } from "./../context/AuthContext";
import { useQuery } from "react-query";
import * as api from "../api/userApi";
import { motion } from "framer-motion";
import AdminPanel from "./start-page-header-assets/AdminPanel";
import TutorPanel from "./start-page-header-assets/TutorPanel";
import DropDownNavigationPane from "./start-page-header-assets/DropDownNavigationPane";
import AccountDropDownPane from "./start-page-header-assets/AccountDropDownPane";
import { size } from "mathjs";
import FontFaceObserver from "fontfaceobserver";
import { useUserSubscriptionStatus } from "../hooks/useUserSubscriptionStatus";

const StartPageHeader = () => {
  const navigate = useNavigate();
  const { user, logout } = UserAuth();
  const [email, setEmail] = useState("");
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);

  //for Admin/Tutor Tools
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const mainMenu = useRef(null);
  const accountMenu = useRef(null);
  const logoMenuButton = useRef(null);
  const accountMenuButton = useRef(null);
  const firstMainMenuElement = useRef(null);
  const firstAccountMenuElement = useRef(null);
  const dropdownButton = useRef(null);
  const accountToggleRef = useRef(null);
  // const [fontLoaded, setFontLoaded] = useState(false);

  const isActiveSubscription = useUserSubscriptionStatus();

  const dropdownVariants = {
    open: { height: "auto", opacity: 1 },
    closed: { height: 0, opacity: 1 },
  };
  const {
    isLoading: isLoadingDetailedUser,
    refetch: refreshCurrentUser,
    data: detailedUser,
    isSuccess,
  } = useQuery(["detailed-user", email], () => api.getDetailedUser(email));

  useEffect(() => {
    if (user) {
      if (user?.email) {
        setEmail(user?.email);
        refreshCurrentUser();
      }
    }
  }, [user]);

  //avoids flash of unstyled font in header:

  // useEffect(() => {
  //   const fontObserver = new FontFaceObserver("Amatic SC");

  //   fontObserver
  //     .load()
  //     .then(() => {
  //       setFontLoaded(true);
  //     })
  //     .catch((error) => {
  //       // console.log("Font failed to load", error);
  //       // Optionally set the font as loaded anyway so the text becomes visible (but in fallback font).
  //       setFontLoaded(true);
  //     });
  // }, []);

  useEffect(() => {
    if (detailedUser) {
    }
  }, [detailedUser]);

  //Enables the ESC key to close either dropdown.

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        if (dropdownIsOpen) {
          // Using optional chaining to guard against null references
          dropdownButton.current?.focus();
          setDropdownIsOpen(false);
        }
        if (accountDropdownOpen) {
          // Using optional chaining to guard against null references
          accountToggleRef.current?.focus();
          setAccountDropdownOpen(false);
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [dropdownIsOpen, accountDropdownOpen]);

  //This is how I should implement my other event listeners to close when click outside
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (dropdownIsOpen) {
      document.body.classList.add("dropdown-open");
    } else {
      document.body.classList.remove("dropdown-open");
    }
  }, [dropdownIsOpen]);

  const handleClickOutside = (event) => {
    const { current: mainMenuVal } = mainMenu;
    const { current: accountMenuVal } = accountMenu;
    const { current: logoMenuButtonVal } = logoMenuButton;
    const { current: accountMenuButtonVal } = accountMenuButton;
    if (
      mainMenuVal &&
      !mainMenuVal.contains(event.target) &&
      !logoMenuButtonVal.contains(event.target)
    ) {
      setDropdownIsOpen(false);
    }
    if (
      accountMenuVal &&
      !accountMenuVal.contains(event.target) &&
      !accountMenuButtonVal.contains(event.target)
    ) {
      setAccountDropdownOpen(false);
    }
  };

  const toggleDropdown = () => {
    if (!dropdownIsOpen) {
      setAccountDropdownOpen(false);
      // Wait until the next render cycle to ensure elements are mounted
      setTimeout(() => {
        // Check if the element is mounted before trying to focus
        if (firstMainMenuElement.current) {
          firstMainMenuElement.current.focus();
        }
      }, 0);
    } else {
      logoMenuButton.current?.focus();
    }
    setDropdownIsOpen(!dropdownIsOpen);
  };

  const handleNavigateClick = (url) => {
    setDropdownIsOpen(false);
    setAccountDropdownOpen(false);
    navigate(url);
  };
  const handleNavigateClickWithState = (url, activeTab) => {
    setDropdownIsOpen(false);
    navigate(url, {
      state: {
        activeTab,
      },
    });
  };
  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      navigate(0);
      setAccountDropdownOpen(false);
    } catch (e) {}
  };

  const toggleAccountDropdown = (e) => {
    e.preventDefault();
    if (user) {
      if (!accountDropdownOpen) {
        setDropdownIsOpen(false);
        // Wait until the next render cycle to ensure elements are mounted
        setTimeout(() => {
          // Check if the element is mounted before trying to focus
          if (firstAccountMenuElement.current) {
            firstAccountMenuElement.current.focus();
          }
        }, 0);
      } else {
        // Using optional chaining to guard against null references
        accountMenuButton.current?.focus();
      }
      setAccountDropdownOpen(!accountDropdownOpen);
    } else {
      navigate("/signin");
    }
  };

  const generateExtraPanel = () => {
    if (detailedUser) {
      if (detailedUser.role === "admin") {
        return (
          <AdminPanel
            handleNavigateClick={handleNavigateClick}
            handleShow={handleShow}
            handleClose={handleClose}
            show={show}
          />
        );
      }
      if (detailedUser.role === "tutor" && isActiveSubscription) {
        return (
          <TutorPanel
            handleNavigateClick={handleNavigateClick}
            handleShow={handleShow}
            handleClose={handleClose}
            show={show}
          />
        );
      }
    }
  };

  const generateName = (limit) => {
    const charLimit = (str, limit) => {
      return str.length > limit ? str.substring(0, limit) + "..." : str;
    };

    if (user) {
      if (
        detailedUser &&
        detailedUser.firstName &&
        typeof detailedUser.firstName !== "undefined" &&
        detailedUser.firstName !== null &&
        !(
          typeof detailedUser.firstName === "object" &&
          Object.keys(detailedUser.firstName).length === 0
        )
      ) {
        return charLimit(detailedUser.firstName, limit);
      } else if (
        detailedUser &&
        detailedUser.emailAddress &&
        typeof detailedUser.emailAddress !== "undefined" &&
        detailedUser.emailAddress !== null &&
        !(
          typeof detailedUser.emailAddress === "object" &&
          Object.keys(detailedUser.emailAddress).length === 0
        )
      ) {
        return charLimit(
          detailedUser.emailAddress.substring(
            0,
            detailedUser.emailAddress.indexOf("@")
          ),
          limit
        );
      } else {
        if (user?.email) {
          return charLimit(
            user?.email.substring(0, user?.email.indexOf("@")),
            limit
          );
        }
      }
    }
  };

  return (
    <Container fluid className="overall-start-page-header p-0">
      <Row className="thin-black-line m-0"></Row>
      <Row className="main-navigation-header">
        <Col
          xs={9}
          sm={7}
          md={{ span: 4, offset: 1 }}
          className="horizontal-spacer-col align-items-center d-flex"
          style={{ zIndex: 1 }}
        >
          <Col xs={"auto"} className="pe-1 me-0">
            <Button
              variant="link"
              className="p-0 m-0 text-decoration-none text-black"
              onClick={toggleDropdown}
              aria-haspopup="true"
              aria-expanded={dropdownIsOpen}
              aria-label="Toggle navigation menu"
              ref={dropdownButton}
            >
              {!dropdownIsOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  size="xl"
                  className="font-awesome-icon ms-1 align-middle ps-2 pe-1 hover-enlarge-most"
                />
              )}
              {dropdownIsOpen && (
                <FontAwesomeIcon
                  icon={faX}
                  size="lg"
                  className="font-awesome-icon ms-1 align-middle hover-enlarge-most pe-2 header-color-button-spacer"
                />
              )}
            </Button>
          </Col>

          <Col
            xs={10}
            sm={10}
            md={12}
            ref={logoMenuButton}
            className="company-logo"
            onClick={() => handleNavigateClick("/")}
            style={{ maxWidth: "280px" }}
          >
            <Button
              variant="link"
              className="m-0 p-0 text-decoration-none bg-color-black rounded-0 w-100 h-100"
              onClick={() => handleNavigateClick("/")}
              aria-label="Navigate to Start Page"
            >
              <Row className="m-0 p-0 w-100">
                <Col
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  className="ms-0 ps-0 pe-0 mt-0 pb-0 mx-0 d-flex align-items-center justify-content-center"
                >
                  <Image
                    src={logo}
                    alt="RESOLVE Test Prep Logo"
                    className="logo-image d-none d-sm-block mb-0 pb-0 mx-0"
                  />
                  <Image
                    src={logo}
                    alt="RESOLVE Test Prep Logo"
                    className="d-block d-sm-none mb-0 pb-0 mx-0"
                    style={{ maxHeight: "3rem" }}
                  />
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} className="my-auto p-0">
                  <div className="pe-0 text-center mb-0 pb-0 py-0 my-0 resolve-header-font-container">
                    <div className="resolve-header-font d-none d-sm-block my-0 py-0 font-visible">
                      RESOLVE
                    </div>

                    <div
                      className="amatic-font font-weight-very-bold text-white d-block d-sm-none my-0 py-0 d-flex align-items-center justify-content-center font-visible"
                      style={{ minHeight: "4.4rem", fontSize: "3.0rem" }}
                    >
                      RESOLVE
                    </div>
                  </div>

                  {/* <h6 className="logo-label logo-label-subscript d-none d-sm-block d-lg-none text-nowrap text-center"> RESOLVE</h6> */}
                  {/* <div className="resolve-subtitle text-nowrap text-center text-white fs-5 mt-0 pt-0">
                                        Test Prep
                                    </div> */}
                </Col>
              </Row>
            </Button>
          </Col>
          {generateExtraPanel()}
        </Col>

        <Col xs={3} sm={5} md={6} className="d-flex justify-content-end">
          <Button
            variant="link"
            className="p-0 m-0 text-decoration-none rounded-0"
            onClick={(e) => toggleAccountDropdown(e)}
            aria-haspopup="true"
            aria-expanded={accountDropdownOpen}
            aria-label="Show Account Tools"
            ref={accountToggleRef}
          >
            <Row
              ref={accountMenuButton}
              className="align-items-center d-flex mt-0 account-info-top-header"
            >
              <Col md={10} className="sign-in-start-header">
                {user && (
                  <>
                    <span className="mt-1 font-weight-bold text-green d-none d-md-block d-lg-none fs-4">
                      Hi, {generateName(13)}!
                    </span>
                    <span className="mt-1 font-weight-bold text-green d-none d-lg-block fs-4">
                      Hi, {generateName(20)}!
                    </span>
                  </>
                )}
                {!user && (
                  <span className="mt-1 font-weight-bold d-none d-md-block fs-4 text-green">
                    Sign In
                  </span>
                )}
              </Col>

              <Col md={2}>
                <FontAwesomeIcon
                  icon={faCircleUser}
                  className={
                    !user
                      ? "font-awesome-icon ms-1 align-middle user-login-icon hover-enlarge-more"
                      : "font-awesome-icon ms-1 align-middle user-login-icon text-green hover-enlarge-more"
                  }
                />
              </Col>
            </Row>
          </Button>
        </Col>
      </Row>

      {dropdownIsOpen && (
        <DropDownNavigationPane
          dropdownVariants={dropdownVariants}
          dropdownIsOpen={dropdownIsOpen}
          mainMenu={mainMenu}
          handleNavigateClick={handleNavigateClick}
          handleNavigateClickWithState={handleNavigateClickWithState}
          firstMainMenuElement={firstMainMenuElement}
        />
      )}

      {accountDropdownOpen && (
        <div className="w-100">
          <AccountDropDownPane
            dropdownVariants={dropdownVariants}
            accountDropdownOpen={accountDropdownOpen}
            accountMenu={accountMenu}
            handleNavigateClick={handleNavigateClick}
            handleLogout={handleLogout}
            firstAccountMenuElement={firstAccountMenuElement}
          />
        </div>
      )}
    </Container>
  );
};
export default StartPageHeader;
