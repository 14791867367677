import React from 'react';
import { Button, Col, Container, Row, Modal } from 'react-bootstrap';
import { UserAuth } from '../../context/AuthContext';
import { useQuery } from 'react-query';
import * as api from '../../api/userApi';

const TooSmallScreenModal = ({show, handleClose}) => {
  const { user } = UserAuth();

  const { isLoading: isLoadingDetailedUser, refetch: refreshCurrentUser, data: detailedUser, isSuccess } = useQuery(
    ["detailed-user", user?.email], () => api.getDetailedUser(user?.email));

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      centered
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Body className="bg-color-black text-white d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div>
          <Row className="justify-content-center mb-4">
            <h1 className='font-weight-bold text-center'>Your Screen is Too Small</h1>
          </Row>
          <Row className="justify-content-center mb-4 px-5">
            <h2>Testing and Practice Modules are only designed for larger devices. Switch to a larger device or expand your window size to continue. </h2>
          </Row>
          <Row className="justify-content-center">
            <Col className="d-flex justify-content-center">
              <Button
                variant="warning"
                className="rounded-pill px-5"
                style={{ color: 'black', fontWeight: 'bold' }}
                onClick={handleClose}
              >
                Exit
              </Button>
            </Col>
          </Row>
        </div>
        <div style={{ position: 'absolute', bottom: '15px', left: '15px' }}>
          {detailedUser?.firstName && (
            <h3 className='mx-5'>{detailedUser?.firstName} {detailedUser?.lastName || ''}</h3>
          )}
          {!detailedUser?.firstName && (
            <h3 className='mx-5'>{user?.email}</h3>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TooSmallScreenModal;
