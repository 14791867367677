const useDisplayTopicTime = (detailedUser) => (topic) => {
  const foundModule = detailedUser?.practiceModules?.find(
    (module) =>
      module[0].problemType === topic.moduleName &&
      module[0].status === "In Progress"
  );

  if (foundModule) {
    return "Resume Topic";
  } else {
    return `${topic.fullModule?.mainDigitalSatWorksheet?.length - 1} questions`;
  }
};

export default useDisplayTopicTime;
