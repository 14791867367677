import { useContext } from "react";
import { useQuery } from "react-query";
import { getDetailedUser } from "../api/userApi";
import { UserAuth } from "../context/AuthContext";
import { getSubscriptionDetails } from "../api/userApi";
import { getLemonSubscriptionDetails } from "../api/userApi";
import { getTutorSubscriptionsByStudentEmail } from "../api/tutorSubscriptionsApi";

export function useUserSubscriptionStatus() {
  const { user } = UserAuth();
  const email = user?.email;

  const { data: detailedUser } = useQuery(
    ["detailed-user", email],
    () => getDetailedUser(email),
    { enabled: !!email } // Only run the query if the email is available
  );

  //fetch tutor subscription by student email
  const { data: tutorSubscription } = useQuery(
    ["tutor-subscription-by-student-email", email],
    () => getTutorSubscriptionsByStudentEmail(email),
    { enabled: !!email } // Only run the query if the email is available
  );

  //depcrecated. Used Stripe for payment processing
  const { data: subscriptionDetails } = useQuery(
    ["subscription-details", detailedUser?.subscriptionId],
    () => getSubscriptionDetails(detailedUser?.subscriptionId),
    { enabled: !!detailedUser?.subscriptionId } // Only run the query if the subscriptionId is available
  );

  const { data: lemonSubscriptionDetails } = useQuery(
    ["lemon-subscription-details", detailedUser?.lemonsqueezySubscriptionId],
    () => getLemonSubscriptionDetails(detailedUser?.lemonsqueezySubscriptionId),
    { enabled: !!detailedUser?.lemonsqueezySubscriptionId } // Only run the query if the subscriptionId is available
  );

  // Query for lemon subscription details using the tutor's subscription ID
  const { data: tutorLemonSubscriptionDetails } = useQuery(
    ["lemon-subscription-details-tutor", tutorSubscription?.subscriptionId],
    () => getLemonSubscriptionDetails(tutorSubscription?.subscriptionId),
    { enabled: !!tutorSubscription?.subscriptionId } // Only run the query if the subscriptionId is available
  );

  const isActiveSubscription =
    detailedUser?.subscriptionStatus === "active" ||
    subscriptionDetails?.isActive ||
    lemonSubscriptionDetails?.status === "active" ||
    lemonSubscriptionDetails?.isActive ||
    tutorSubscription?.subscriptionStatus === "active" ||
    tutorLemonSubscriptionDetails?.isActive;

  return isActiveSubscription || false;
}
