import { useState, useEffect } from "react";

const useSubscriptionAlert = () => {
  const [subscriptionAlert, setSubscriptionAlert] = useState(false);

  useEffect(() => {
    if (subscriptionAlert) {
      const timeoutId = setTimeout(() => {
        setSubscriptionAlert(false);
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [subscriptionAlert]);

  const triggerAlert = () => setSubscriptionAlert(true);

  return { subscriptionAlert, triggerAlert };
};

export default useSubscriptionAlert;
