import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getProblemTypesByIncidence } from '../api/problemApi';

export default function useProblemTypes(testType, sectionType, arrayOfMostRecentTests) {
    const [wrongProblems, setWrongProblems] = useState([]);

    const { isLoading, data: problemTypes } = useQuery(
        ["problem-types-priority-" + sectionType], 
        () => getProblemTypesByIncidence(testType, sectionType), 
        {
            refetchOnWindowFocus: false,
            staleTime: Infinity, // Set staleTime to prevent the data from going stale
            refetchOnMount: false,
            refetchOnReconnect: false, 
    });
  
 

    useEffect(() => {
        if (isLoading || !problemTypes || arrayOfMostRecentTests.length === 0) {
            return;
        }

        let tempWrong = [];
        arrayOfMostRecentTests.forEach((test) => {
            for (let i = 1; i < test.length; i++) {
                let section = test[i].slice(1);
                for (let j = 0; j < section.length; j++) {
                    let problem = section[j];

                    if (problem.status !== 'correct' && problemTypes?.some(element => element.questionType === problem.problemType)) {
                        tempWrong.push(problem);
                    }
                }
            }
        });

        setWrongProblems(groupAndSortProblems(tempWrong, problemTypes));
    }, [isLoading, problemTypes, arrayOfMostRecentTests]);

    const calculateScore = (problemType, totalNumOfProblems, totalPercentOfAllQuestions) => {
        const numOfProblemsScore = (problemType.arrayOfProblems.length / totalNumOfProblems) * 100;
        const percentOfAllQuestionsScore = (problemType.percentOfAllQuestions / totalPercentOfAllQuestions) * 100;
        return 0.5 * numOfProblemsScore + 0.5 * percentOfAllQuestionsScore;
    };

    const groupAndSortProblems = (problems, problemTypes) => {
        const groupedProblems = [];
        problems.forEach((problem) => {
            const problemType = problemTypes.find((element) => element.questionType === problem.problemType);
            if (problemType) {
                const problemTypeIndex = groupedProblems.findIndex((group) => group.problemType === problemType.questionType);
                if (problemTypeIndex !== -1) {
                    groupedProblems[problemTypeIndex].arrayOfProblems.push(problem);
                } else {
                    groupedProblems.push({
                        problemType: problemType.questionType,
                        percentOfAllQuestions: problemType.percentOfAllQuestions,
                        arrayOfProblems: [problem],
                    });
                }
            }
        });

        const totalNumOfProblems = groupedProblems.reduce((total, problemType) => total + problemType.arrayOfProblems.length, 0);
        const totalPercentOfAllQuestions = groupedProblems.reduce((total, problemType) => total + problemType.percentOfAllQuestions, 0);

        groupedProblems.forEach(problemType => {
            problemType.calculatedScore = calculateScore(problemType, totalNumOfProblems, totalPercentOfAllQuestions);
        });

        return groupedProblems.sort((a, b) => {
            return calculateScore(b, totalNumOfProblems, totalPercentOfAllQuestions) - calculateScore(a, totalNumOfProblems, totalPercentOfAllQuestions);
        });
    };

    return wrongProblems;
}
