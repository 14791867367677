import React from "react";
import { Table } from "react-bootstrap";
import useHasPracticeModuleBeenCompletedSinceAssigned from "../../../hooks/test-info/useHasPracticeModuleBeenCompletedSinceAssigned";

const PracticeTopicsTable = ({
  topics,
  handleTopicClick,
  displayTopicTime,
  tutorDisplay = false,
}) => {
  const {
    checkCompletion: hasModuleBeenCompleted,
    isLoading,
    isError,
    error,
  } = useHasPracticeModuleBeenCompletedSinceAssigned();

  if (!tutorDisplay) {
    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error: {error.message}</div>;
  }

  if (!topics) return <div>No topics available.</div>;

  const sortedTopics = topics?.sort((a, b) => {
    if (!tutorDisplay) {
      const aCompleted = hasModuleBeenCompleted(a.moduleName, a.assignedDate);
      const bCompleted = hasModuleBeenCompleted(b.moduleName, b.assignedDate);
      if (aCompleted && !bCompleted) return 1;
      if (!aCompleted && bCompleted) return -1;
    }
    return a.moduleName.localeCompare(b.moduleName);
  });

  if (!sortedTopics?.length) return null;

  return (
    <div className="text-black text-center">
      <h3 className="fs-6 p-0 m-0 mb-0 text-center text-white bg-color-black font-weight-very-bold">
        Practice Topics
      </h3>
      <Table hover size="sm" className="text-black">
        <tbody>
          {sortedTopics.map((topic) => {
            const isCompleted = tutorDisplay
              ? false
              : hasModuleBeenCompleted(topic.moduleName, topic.assignedDate);
            return (
              <tr
                key={topic.moduleName}
                className={`${
                  isCompleted ? "text-green" : "hover-enlarge-more"
                }`}
                onClick={!isCompleted ? () => handleTopicClick(topic) : null}
              >
                <td className="text-start">
                  {isCompleted && <span>&#x2713; </span>}
                  {topic.moduleName}
                </td>
                <td className="text-end">
                  {isCompleted ? "Completed" : displayTopicTime(topic)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default PracticeTopicsTable;
