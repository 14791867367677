import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';
import Signin from '../Signin';

const ProtectedRoute = ({ children }) => {

    const {user} = UserAuth();

//     if (!user) {
//         return <Navigate to='/' />
//     }
//   return children;

    return !user ?  <Signin /> : children ;



};

export default ProtectedRoute;