import React from "react";
import { Row, Col, Container, Image } from "react-bootstrap";

const TutorToolsBanner = () => {
  const email = "info" + "@" + "resolveprep.com";

  return (
    <div className="bg-color-offwhite py-5">
      <div className="bg-color-dark-green">
        <div className="text-center font-weight-bold text-white amatic-font display-4">
          Tutor Tools
        </div>
        <Container className="bg-color-offwhite" fluid>
          <Row className="d-flex justify-content-center fs-5 pt-3 mx-1">
            Resolve Prep isn't just for students. We have a suite of tools to
            help tutors maximize student progress.
          </Row>
          <Row className="d-flex justify-content-center">
            {/* <Col xs={12} md={6} xxl={4} className=' mt-2'>
                            <Row className='fs-5 font-weight-bold my-2 text-center'>
                                <Col className='d-flex align-items-center justify-content-center bg-color-dark-purple rounded-2 mx-5'>
                                    <span className=' text-white px-3 py-2 rounded-2'>
                                        Manage Student Assignments
                                    </span>

                                </Col>
                            </Row>
                            <Row className='fs-5'>
                                <Col className='d-flex justify-content-center text-center'>
                                    Assign practice tests, homework, and more to your students.
                                </Col>

                            </Row>
                            <Row className='fs-5 font-weight-bold my-2 text-center'>
                            <Col className='d-flex align-items-center justify-content-center bg-color-dark-purple rounded-2 mx-5'>
                                    <span className='bg-color-dark-purple text-white px-3 py-2 rounded-2'>
                                        Track Student Progress
                                    </span>

                                </Col>
                            </Row>
                            <Row className='fs-5'>
                                <Col className='d-flex justify-content-center text-center'>
                                    Monitor your students' progress and identify areas for improvement.
                                </Col>

                            </Row>
                            <Row className='fs-5 font-weight-bold my-2 text-center'>
                            <Col className='d-flex align-items-center justify-content-center bg-color-dark-purple rounded-2 mx-5'>
                                    <span className='bg-color-dark-purple text-white px-3 py-2 rounded-2'>
                                        Review Student Practice Tests
                                    </span>

                                </Col>
                            </Row>
                            <Row className='fs-5'>
                                <Col className='d-flex justify-content-center text-center'>
                                    Review your students' practice tests and provide feedback.
                                </Col>
                            </Row>
                        </Col> */}
            <Col
              xs={12}
              md={6}
              xxl={4}
              className="d-flex align-items-center justify-content-center mt-3"
            >
              <Image
                src={"/images/tutor-tools-promo-small.webp"}
                style={{ maxHeight: "20rem", width: "auto" }}
              />
            </Col>
          </Row>

          <Row className="d-flex justify-content-center">
            <Col className="text-center fs-4">
              If you're a tutor and want access to our beta tutor tools,
              <a href={`mailto:${email}`} className="purple-text mx-1">
                contact us
              </a>{" "}
              today!
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default TutorToolsBanner;
