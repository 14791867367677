import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { UserAuth } from '../context/AuthContext';
import { getDetailedUser } from '../api/userApi';
import { det } from 'mathjs';

const useTotalTimeForPracticeModule = (practiceModule) => {
  const { user } = UserAuth();
  const [totalTime, setTotalTime] = useState(0);
  const [formattedTime, setFormattedTime] = useState('');

  const { isLoading: isLoadingDetailedUser, refetch: refreshCurrentUser, data: detailedUser, isSuccess } = useQuery(
    ["detailed-user", user?.email], () => getDetailedUser(user?.email));

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    let formattedTime = '';

    if (hours > 0) {
      formattedTime += `${hours} hr${hours > 1 ? 's' : ''}, `;
    }

    if (minutes > 0) {
      formattedTime += `${minutes} min${minutes > 1 ? 's' : ''}`;
    }

    if (hours === 0 && seconds > 0) {
      formattedTime += `, ${seconds} sec${seconds > 1 ? 's' : ''}`;
    }

    return formattedTime;
  };

  useEffect(() => {
    const calculateTotalTimeForPracticeModule = () => {
      if (practiceModule) {
        const numberOfQuestions = practiceModule.length - 1;
        let totalTime = numberOfQuestions * 120;
        if (detailedUser?.extraTimeStatus === "Extended Time"){
          totalTime = totalTime * 1.5;
        }
        if (detailedUser?.extraTimeStatus === "Double Time"){
          totalTime = totalTime * 2;
        }
        setTotalTime(totalTime);
        setFormattedTime(formatTime(totalTime));
      }
    };

    calculateTotalTimeForPracticeModule();
  }, [practiceModule, detailedUser]);

  return { totalTime, formattedTime };
};

export default useTotalTimeForPracticeModule;
