import axios from "axios";
import { app } from "../context/AuthContext";

const mongoApi = axios.create({
  baseURL: process.env.REACT_APP_MONGODB_BASE_URL,
});

const getAuthConfig = async () => {
  //console.log("get auth config called");
  if (!app.currentUser) {
    return null;
  }

  try {
    await app.currentUser.refreshAccessToken();
  } catch (error) {
    console.error("Failed to refresh access token:", error);
    return null; // Return null if refreshing the token fails
  }

  const accessToken = app.currentUser.accessToken;

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

// This updates a digital test with token authentication. Tested.
export const updateTest = async (updatedTest) => {
  const config = await getAuthConfig();
  const res = await mongoApi.post("updatedigitalsat", updatedTest, config);
  return res.data;
};

// This retrieves all digital tests with token authentication. Tested.
export const getAllTests = async () => {
  const config = await getAuthConfig();
  const res = await mongoApi.get("getalldigitalsattests", config);
  return res.data;
};

// This retrieves a digital test by ID with token authentication. Tested.
export const getTestById = async (testId) => {
  const config = await getAuthConfig();
  config.params = {
    testId: testId,
  };
  const res = await mongoApi.get("getdigitalsattestbyid", config);
  return res.data.body;
};

// This retrieves a digital test by ID with token authentication. Tested.
export const getTestByTestName = async (testName) => {
  const config = await getAuthConfig();
  config.params = {
    testName: testName,
  };
  const res = await mongoApi.get("getdigitalsatbyname", config);
  return res.data.body;
};

// This retrieves all blacklisted problems with token authentication. Tested.
export const getAllBlacklistedProblems = async () => {
  const config = await getAuthConfig();
  if (!config) {
    console.error("Failed to get auth config.");
    return null;
  }

  try {
    const res = await mongoApi.get("getblacklistedproblems", config);
    return res.data;
  } catch (error) {
    if (error.response) {
      // Request made and server responded
      console.error("Error response data:", error.response.data);
      console.error("Error response status:", error.response.status);
      console.error("Error response headers:", error.response.headers);
    } else if (error.request) {
      // Request made but no response received
      console.error("Error request:", error.request);
    } else {
      // Something happened in setting up the request
      console.error("Error message:", error.message);
    }
    console.error("Error config:", error.config);
    throw error;
  }
};

// This retrieves a digital test by ID with token authentication. Tested.
export const getDetailedStatsForDigitalSAT = async (testName) => {
  const config = await getAuthConfig();
  config.params = {
    testName: testName,
  };
  const res = await mongoApi.get("getdetailedstatsfordigitalsat", config);
  return JSON.parse(res.data.body);
};

export const updateUserBluebookTest = async (test) => {
  if (!app.currentUser) {
    return null;
  }

  // Refresh the access token and get it
  await app.currentUser.refreshAccessToken();
  const accessToken = app.currentUser.accessToken;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    // console.log("test", test);
    const res = await mongoApi.post("updateuserbluebooktest", test, config);
    // console.log("res.data.body", JSON.parse(res.data.body));
    return JSON.parse(res.data.body);
  } catch (error) {
    throw error;
  }
};

//requires no auth

export const getAllDigitalSatNamesNoAuth = async () => {
  const res = await mongoApi.get("getalldigitalsatnameswithnoauth");
  //console.log("res.data", res.data);
  return res.data;
};
