import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import "./UserNotSignedInCard.css"; // Import the CSS file

const UserNotSignedInCard = () => {
  const navigate = useNavigate();
  const { user } = UserAuth();
  // Update the URLs to point to the local public folder
  const images = [
    "/images/frame-screenshot-1_small.jpg",
    "/images/frame-screenshot-2_small.jpg",
    "/images/frame-screenshot-3_small.jpg",
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 6000); // Change image every 5 seconds
    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  const handleSubscribeClick = () => {
    if (!user) {
      navigate("/signup");
    } else {
      navigate("/computertests");
    }
  };

  return (
    <>
      {/* For MD and Larger Screens */}
      <div
        className="d-none d-sm-block d-md-none user-card-small"
        onClick={() => handleSubscribeClick()}
      >
        <img
          src="/images/computer-frame.webp"
          alt="image-advertising-practice-tests-and-video-explanations"
          className="user-card-image-small"
        />

        <div className="user-card-animate-small">
          <AnimatePresence mode="wait">
            <motion.img
              key={currentImageIndex}
              src={images[currentImageIndex]}
              alt="screenshot"
              className="screenshot-image-small"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 1.0 } }}
              exit={{ opacity: 0 }}
            />
          </AnimatePresence>
        </div>
      </div>

      {/* For MD and Larger Screens */}
      <div
        className="d-none d-md-block user-card-large"
        onClick={() => handleSubscribeClick()}
      >
        <img
          src="/images/computer-frame.webp"
          alt="image-advertising-practice-tests-and-video-explanations"
          className="user-card-image-large"
        />

        <div className="user-card-animate-large">
          <AnimatePresence mode="wait">
            <motion.img
              key={currentImageIndex}
              src={images[currentImageIndex]}
              alt="screenshot"
              className="screenshot-image-large"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 1.0 } }}
              exit={{ opacity: 0 }}
            />
          </AnimatePresence>
        </div>
      </div>
    </>
  );
};

export default UserNotSignedInCard;
