import React, { useEffect, lazy, Suspense } from "react";
import { Col, Row } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";

import ProtectedRoute from "./components/protected-routes/ProtectedRoute";
// import CreateTestModule from "./components/tutor-tools/CreateTestModule";
import { MathJaxContext } from "better-react-mathjax";
import StartPage from "./components/StartPage";
import WebFont from "webfontloader";
import { BlacklistedProblemsProvider } from "./context/BlacklistProblemsContext";
import "./load-css";

// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";

import AdminProtectedRoute from "./components/protected-routes/AdminProtectedRoute";

import LoadingSpinner from "./components/LoadingSpinner";

import TutorProtectedRoute from "./components/protected-routes/TutorProtectedRoute";

const Signup = lazy(() => import("./components/Signup"));

const TrainingModuleMainWrapper = lazy(() =>
  import("./components/training-modules/TrainingModuleMainWrapper")
);

const Signin = lazy(() => import("./components/Signin"));

const Account = lazy(() => import("./components/account/Account"));

const Subscribe = lazy(() => import("./components/subscribe/Subscribe"));

const SingleBlogPost = lazy(() => import("./components/blog/SingleBlogPost"));

const ConfirmEmail = lazy(() =>
  import("./components/email-confirmation/ConfirmEmail")
);

const PasswordReset = lazy(() =>
  import("./components/email-confirmation/PasswordReset")
);

const PasswordResetConfirmation = lazy(() =>
  import("./components/email-confirmation/PasswordResetConfirmation")
);

const VocabWordsUsed = lazy(() =>
  import("./components/admin-tools/used-vocab-words/VocabWordsUsed")
);

const AllBlogPosts = lazy(() =>
  import("./components/blog/blog-edit/AllBlogPosts")
);

const BlogLandingPage = lazy(() => import("./components/blog/BlogLandingPage"));

const BlogEntryEditor = lazy(() =>
  import("./components/blog/blog-edit/BlogEntryEditor")
);

const OfficialTestInput = lazy(() =>
  import("./components/official-test-input/OfficialTestInput")
);

const ProblemStatistics = lazy(() =>
  import("./components/admin-tools/test-statistics/ProblemStatistics")
);

const ThankYou = lazy(() => import("./components/subscribe/ThankYou"));

const ShowIndividualTestScale = lazy(() =>
  import(
    "./components/admin-tools/test-scales-and-answers/ShowIndividualTestScale"
  )
);

const ShowIndividualAnswerKey = lazy(() =>
  import(
    "./components/admin-tools/test-scales-and-answers/ShowIndividualAnswerKey"
  )
);

const SingleCollege = lazy(() =>
  import("./components/applications/SingleCollege")
);

const ActTestingPage = lazy(() =>
  import("./components/act-testing/ActTestingPage")
);

const PrivateTutoring = lazy(() =>
  import("./components/private-tutoring/PrivateTutoring")
);

const PrivacyPolicy = lazy(() =>
  import("./components/privacy-and-compliance/PrivacyPolicy")
);

const ModuleScorePage = lazy(() =>
  import("./components/practice/ModuleScorePage")
);

const MainLearnSplash = lazy(() =>
  import("./components/learn/MainLearnSplash")
);

const MainLearnInterface = lazy(() =>
  import("./components/learn/individual-topic-learn/MainLearnInterface")
);

const AllComputerTests = lazy(() =>
  import("./components/computer-tests/AllComputerTests")
);

const MainDigitalSATTestWrapper = lazy(() =>
  import(
    "./components/computer-tests/digital-sat-test/MainDigitalSATTestWrapper"
  )
);

const ScorePage = lazy(() =>
  import("./components/computer-tests/digital-sat-test/stages/ScorePage")
);

const EditIndividualProblem = lazy(() =>
  import("./components/admin-tools/edit-problem/EditIndividualProblem")
);
const DigitalSATEdit = lazy(() =>
  import("./components/admin-tools/digital-sat-edit/DigitalSATEdit")
);

const AdminTools = lazy(() =>
  import("./components/admin-tools/user-edit/AdminTools")
);

const SchoolEntry = lazy(() =>
  import("./components/admin-tools/college-edit/SchoolEntry")
);

const ViewAllSchools = lazy(() =>
  import("./components/admin-tools/college-edit/ViewAllSchools")
);

const TestScales = lazy(() =>
  import("./components/admin-tools/test-scales-and-answers/TestScales")
);

const ReadTestScales = lazy(() =>
  import("./components/admin-tools/test-scales-and-answers/ReadTestScales")
);

const EditIndividualModule = lazy(() =>
  import("./components/admin-tools/learning-module-edit/EditIndividualModule")
);

const ViewAllLearningModules = lazy(() =>
  import("./components/admin-tools/learning-module-edit/ViewAllLearningModules")
);

const ViewAllDigitalSAT = lazy(() =>
  import("./components/admin-tools/digital-sat-edit/ViewAllDigitalSAT")
);

const BlackListTopicsMain = lazy(() =>
  import("./components/admin-tools/blacklist-topics/BlackListTopicsMain")
);

const VocabularyInput = lazy(() =>
  import("./components/admin-tools/edit-vocabulary/VocabularyInput")
);

const ViewAllVocabulary = lazy(() =>
  import("./components/admin-tools/edit-vocabulary/ViewAllVocabulary")
);

const DeckList = lazy(() => import("./components/vocabulary/DeckList"));

const VocabReviewMainWrapper = lazy(() =>
  import("./components/vocabulary/VocabReviewMainWrapper")
);

const ViewSingleVocabCard = lazy(() =>
  import("./components/vocabulary/VocabStudy")
);

const Applications = lazy(() =>
  import("./components/applications/Applications")
);

const Colleges = lazy(() => import("./components/applications/Colleges"));

const ThePath = lazy(() => import("./components/applications/ThePath"));

const AboutSat = lazy(() => import("./components/about/AboutSat"));

const AboutAct = lazy(() => import("./components/about/AboutAct"));

const AiChat = lazy(() => import("./components/ai-chat/AiChat"));

const NewPracticeModules = lazy(() =>
  import("./components/practice/NewPracticeModules")
);

const ProblemTypes = lazy(() =>
  import("./components/tutor-tools/ProblemTypes")
);

const GraphingCalculator = lazy(() =>
  import("./components/calculator/GraphingCalculator")
);

const DisplaySingleProblemType = lazy(() =>
  import("./components/tutor-tools/DisplaySingleProblemType")
);

const PracticeModuleMainWrapper = lazy(() =>
  import("./components/practice-modules/PracticeModuleMainWrapper")
);

const Tests = lazy(() => import("./components/Tests"));

const TestHistory = lazy(() => import("./components/test-results/TestHistory"));

const GradeTest = lazy(() => import("./components/grade-test/GradeTest"));

const ManageStudents = lazy(() =>
  import("./components/manage-students/ManageStudents")
);

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  const location = useLocation();

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Grape Nuts"],
      },
    });
  }, []);

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://cdn.paddle.com/paddle/v2/paddle.js";
  //   script.async = true;
  //   script.onload = () => {
  //     // Initialize paddle with vendor id from env variables
  //     const vendorId = Number(process.env.REACT_APP_PADDLE_VENDOR_ID);

  //     window.Paddle && window.Paddle.Setup({
  //       seller: 14521,
  //       eventCallback: function(data) {
  //         if (data.name === "checkout.completed") {
  //           // Create and dispatch the event
  //           const event = new CustomEvent("paddleCheckoutComplete");
  //           window.dispatchEvent(event);
  //         }
  //       }
  //     });

  //   };
  //   document.body.appendChild(script);
  // }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.lemonsqueezy.com/js/lemon.js";
    script.defer = true;
    script.onload = () => {
      if (window.LemonSqueezy) {
        window.LemonSqueezy.Setup({
          eventHandler: (data) => {
            if (data.event === "Checkout.Success") {
              // Send event to Google Analytics
              if (window.gtag) {
                window.gtag("event", "conversion", {
                  send_to: "AW-11182558444/QAJCCP_kq7MYEOyZodQp",
                  transaction_id:
                    data.data.id || data.data.attributes.identifier || "",
                });
                console.log("Gtag event triggered!"); // alert for visual confirmation
              } else {
                console.log("Gtag is not defined!"); // alert for visual confirmation
              }

              // Send event to Reddit Pixel
              if (window.rdt) {
                window.rdt("track", "Purchase", {
                  value: 5, // Ensure this field matches your data schema
                  currency: "USD",
                });
                console.log("Reddit Pixel conversion event triggered!");
              } else {
                console.log("Reddit Pixel is not defined!"); // alert for visual confirmation
              }

              // Dispatch a custom event to notify components of the checkout completion
              const checkoutCompleteEvent = new Event("lemonCheckoutComplete");
              window.dispatchEvent(checkoutCompleteEvent);
            }
            // ... Handle other events as necessary
          },
        });
      } else {
        // console.log("LemonSqueezy is NOT available on window. Something went wrong.");
      }
    };
    document.body.appendChild(script);
  }, []);

  const config = {
    tex: {
      inlineMath: [["$", "$"]],
      displayMath: [["$$", "$$"]],
      processEscapes: true, // if you want to process escapes
    },
    startup: {
      typeset: false,
      pageReady() {
        const options = window.MathJax.startup.document.options;
        const BaseMathItem = options.MathItem;
        options.MathItem = class FixedMathItem extends BaseMathItem {
          assistiveMml(document) {
            if (this.display !== null) super.assistiveMml(document);
          }
        };
        return window.MathJax.startup.defaultPageReady();
      },
    },
  };

  return (
    <div className="container-fluid p-0 w-100">
      {/* <Elements stripe={stripePromise}> */}
      <AuthContextProvider>
        <BlacklistedProblemsProvider>
          <MathJaxContext
            config={config}
            src="https://cdn.jsdelivr.net/npm/mathjax@3.0.1/es5/tex-mml-chtml.js"
            onError={(error) =>
              console.error("Mathjax has failed to load!", error)
            }
            onStartUp={(mathJax) => console.log("MathJax onStartUp", mathJax)}
          >
            <Row className="p-0 m-0 w-100">
              <Col className={`p-0 main-screen-container-false`}>
                <Routes>
                  <Route
                    path="/signin"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <Signin />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/subscribe"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <Subscribe />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/cancel"
                    element={
                      <Subscribe
                      // stripePromise={stripePromise}
                      />
                    }
                  />
                  <Route path="/start" element={<StartPage />} />
                  <Route
                    path="/signup"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <Signup />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/confirmemail"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ConfirmEmail />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/passwordreset"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <PasswordReset />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/passwordresetconfirmation"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <PasswordResetConfirmation />
                      </Suspense>
                    }
                  />
                  {/* <Route path="/loadingspinner" element={<LoadingSpinner />} /> */}

                  <Route path="/" element={<StartPage />} />
                  <Route path="/*" element={<StartPage />} />
                  <Route
                    path="/blog"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <BlogLandingPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/blog/:slug"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <SingleBlogPost />
                      </Suspense>
                    }
                  />

                  <Route
                    path="/tests"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <Tests />
                      </Suspense>
                    }
                  />

                  <Route
                    path="/acttests"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ActTestingPage />
                      </Suspense>
                    }
                  />

                  <Route
                    path="/thankyou"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ThankYou />
                      </Suspense>
                    }
                  />

                  <Route
                    path="/computertests"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <AllComputerTests />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/digitalsat"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <MainDigitalSATTestWrapper />
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/scorepage"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <ScorePage />
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/testhistory"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <TestHistory />
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/gradetest"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <GradeTest />
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/problemtypes"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <AdminProtectedRoute>
                          <ProblemTypes />
                        </AdminProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/trainingmodule"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <AdminProtectedRoute>
                          <TrainingModuleMainWrapper />
                        </AdminProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/vocabwordsused"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <AdminProtectedRoute>
                          <VocabWordsUsed />
                        </AdminProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/singleproblemtype"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <DisplaySingleProblemType />
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/practicemodules"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <NewPracticeModules />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/newpracticemodules"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <NewPracticeModules />
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/modulescorepage"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <ModuleScorePage />
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/colleges"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <Colleges />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/singlecollege"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <SingleCollege />
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/module"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <PracticeModuleMainWrapper />
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/graphingcalculator"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <GraphingCalculator />
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  {/* <Route
                      path="/createtestmodule"
                      element={
                        <ProtectedRoute>
                          <CreateTestModule />
                        </ProtectedRoute>
                      }
                    /> */}
                  <Route
                    path="/account"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <Account
                          // stripePromise={stripePromise}
                          />
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/applications"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <Applications />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/thepath"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ThePath />
                      </Suspense>
                    }
                  />

                  <Route
                    path="/aboutsat"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <AboutSat />
                      </Suspense>
                    }
                  />

                  <Route
                    path="/aboutact"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <AboutAct />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/aichat"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <AiChat />
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/calculator"
                    element={
                      <ProtectedRoute>
                        <GraphingCalculator />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/learn"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <MainLearnSplash />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/learnsingletype"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <MainLearnInterface />
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/privatetutoring"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <PrivateTutoring />
                      </Suspense>
                    }
                  />

                  <Route
                    path="/blog-entry-editor"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <AdminProtectedRoute>
                            <BlogEntryEditor />
                          </AdminProtectedRoute>
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/all-blog-posts"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <AdminProtectedRoute>
                            <AllBlogPosts />
                          </AdminProtectedRoute>
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/admintools"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <AdminProtectedRoute>
                            <AdminTools />
                          </AdminProtectedRoute>
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/managestudents"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <TutorProtectedRoute>
                            <ManageStudents />
                          </TutorProtectedRoute>
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/problemstatistics"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <AdminProtectedRoute>
                            <ProblemStatistics />
                          </AdminProtectedRoute>
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/inputschool"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <AdminProtectedRoute>
                            <SchoolEntry />
                          </AdminProtectedRoute>
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/viewallschools"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <AdminProtectedRoute>
                            <ViewAllSchools />
                          </AdminProtectedRoute>
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/testscales"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <AdminProtectedRoute>
                            <TestScales />
                          </AdminProtectedRoute>
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/showindividualscale"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <ShowIndividualTestScale />
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/showindividualanswerkey"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <ShowIndividualAnswerKey />
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/readtestscales"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <AdminProtectedRoute>
                            <ReadTestScales />
                          </AdminProtectedRoute>
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />

                  <Route
                    path="/editindividualproblem"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <AdminProtectedRoute>
                            <EditIndividualProblem />
                          </AdminProtectedRoute>
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />

                  <Route
                    path="/editlearningmodule"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <AdminProtectedRoute>
                            <EditIndividualModule />
                          </AdminProtectedRoute>
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/viewalllearningmodules"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <AdminProtectedRoute>
                            <ViewAllLearningModules />
                          </AdminProtectedRoute>
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/editdigitalsat"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <AdminProtectedRoute>
                            <DigitalSATEdit />
                          </AdminProtectedRoute>
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/viewalldigitalsat"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <AdminProtectedRoute>
                            <ViewAllDigitalSAT />
                          </AdminProtectedRoute>
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/blacklisttopics"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <AdminProtectedRoute>
                            <BlackListTopicsMain />
                          </AdminProtectedRoute>
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/inputofficialtest"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <OfficialTestInput />
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/editvocabularyword"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <AdminProtectedRoute>
                            <VocabularyInput />
                          </AdminProtectedRoute>
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/viewsinglevocabcard"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <AdminProtectedRoute>
                            <ViewSingleVocabCard />
                          </AdminProtectedRoute>
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/vocab"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <DeckList />
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/vocabprep"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <VocabReviewMainWrapper />
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/viewallvocab"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <ProtectedRoute>
                          <AdminProtectedRoute>
                            <ViewAllVocabulary />
                          </AdminProtectedRoute>
                        </ProtectedRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/privacy-policy"
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <PrivacyPolicy />
                      </Suspense>
                    }
                  />
                </Routes>
              </Col>
            </Row>
          </MathJaxContext>
        </BlacklistedProblemsProvider>
      </AuthContextProvider>
      {/* </Elements> */}
    </div>
  );
}

export default App;
