import { useCallback, useEffect, useState } from "react";
import { getUserVocabDecksByEmail } from "../../api/vocabularyApi";
import { useQuery } from "react-query";
import { UserAuth } from "../../context/AuthContext";

const useVocabDeckStatus = () => {
  const { user } = UserAuth();
  const [vocabDeckStatus, setVocabDeckStatus] = useState({});
  const [error, setError] = useState(null);

  const {
    data: userVocabDecks,
    isLoading,
    isError,
  } = useQuery(
    ["user-vocab-decks", user?.email],
    () => {
      if (!user?.email) {
        throw new Error("User not authenticated");
      }
      return getUserVocabDecksByEmail(user?.email);
    },
    {
      onError: (err) => {
        setError(err.message);
      },
      enabled: !!user?.email, // Only run the query if the user is authenticated
    }
  );

  const calculateStatus = useCallback(
    (deckName) => {
      const deck = userVocabDecks?.find((d) => d.name === deckName);
      if (!deck || typeof deck.averageWordLevel !== "number")
        return "Start Now!";

      if (deck.allWordsMastered) {
        return "Mastered";
      } else {
        const roundedLevel = Math.round(deck.averageWordLevel);
        return `Level: ${roundedLevel}`;
      }
    },
    [userVocabDecks]
  );

  useEffect(() => {
    if (userVocabDecks) {
      const status = {};
      userVocabDecks.forEach((deck) => {
        status[deck.name] = calculateStatus(deck.name);
      });
      setVocabDeckStatus(status);
    }
  }, [userVocabDecks, calculateStatus]);

  return {
    vocabDeckStatus,
    isLoading,
    isError,
    error,
  };
};

export default useVocabDeckStatus;
