import React, { useState, useEffect, useContext } from 'react';
import { getAllBlacklistedProblems } from '../api/digitalSATApi';
import { UserAuth } from './AuthContext';

const BlacklistedProblemsContext = React.createContext({});

export const BlacklistedProblemsProvider = ({ children }) => {
  const [blacklistedProblems, setBlacklistedProblems] = useState([]);
  const { user } = UserAuth();

  useEffect(() => {
    if (!user) return; // Exit early if user is not defined
    
    const fetchData = async () => {
      const problems = await getAllBlacklistedProblems();
      setBlacklistedProblems(problems);
    };

    fetchData();
  }, [user]); // Dependency on user, so the useEffect reruns when user changes

  const refetch = async () => {
    const problems = await getAllBlacklistedProblems();
    setBlacklistedProblems(problems);
  };

  const isBlacklisted = (problemId) => {
    return blacklistedProblems.some(problem => problem[0] === problemId);
  };

  const isRepeatedOnAnotherTest = (problemID, testName) => {
    const problems = blacklistedProblems.filter((problem) => problem[0] === problemID);
    return problems.length > 1 && problems.some((problem) => problem[1] !== testName);
  };

  const isRepeatedOnThisTestOnly = (problemID, testName) => {
    const matchingProblems = blacklistedProblems.filter(problem => problem[0] === problemID);
    return matchingProblems.length > 1 && matchingProblems.every(problem => problem[1] === testName);
  };

  return (
    <BlacklistedProblemsContext.Provider value={{ blacklistedProblems, refetch, isBlacklisted, isRepeatedOnThisTestOnly, isRepeatedOnAnotherTest }}>
      {children}
    </BlacklistedProblemsContext.Provider>
  );
};

export const useBlacklistedProblems = () => {
  return useContext(BlacklistedProblemsContext);
};
