import { useEffect, useState } from "react";

const useDisableLinks = (user, detailedUser) => {
  const [disabledLinks, setDisabledLinks] = useState(false);

  useEffect(() => {
    if (user.email !== detailedUser.emailAddress) {
      setDisabledLinks(true);
    }
  }, [user, detailedUser]);

  return disabledLinks;
};

export default useDisableLinks;
