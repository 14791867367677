import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';
import Signin from '../Signin';
import StartPage from '../StartPage';
import Welcome from '../Welcome';
import { useQuery } from 'react-query';
import { getDetailedUser } from '../../api/userApi';

const ProtectedRoute = ({ children }) => {
    const {user} = UserAuth();

    const { isLoading: isLoadingDetailedUser, refetch: getRefreshedDetailedUser, data: detailedUser, isSuccess } = useQuery(
        ["detailed-user", user?.email], () => getDetailedUser(user?.email));


    return detailedUser?.role === "admin" ?  children : <StartPage /> ;



};

export default ProtectedRoute;