import React, { useEffect, useState } from "react";
import { Card, Row, Col, Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { getAllTests } from "../../api/digitalSATApi";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import useGetAllTests from "../../hooks/fetching/useGetAllTests";
import useSubscriptionAlert from "../../hooks/subscription/useSubscriptionAlert";
import useLaunchTest from "../../hooks/navigation-hooks/useLauchDigitalSat";
import useLaunchPracticeModule from "../../hooks/navigation-hooks/useLaunchPracticeModule";
import PracticeTestsTable from "./tutor-assignments-card-components/PracticeTestsTable";
import PracticeTopicsTable from "./tutor-assignments-card-components/PracticeTopicsTable";
import VocabDecksTable from "./tutor-assignments-card-components/VocabDecksTable";
import useDisableLinks from "../../hooks/navigation-hooks/useDisableLinks";
import useDisplayTestTime from "../../hooks/test-info/useDisplayTestTime";
import useDisplayTopicTime from "../../hooks/test-info/useDisplayTopicTime";

const TutorAssignmentsCard = ({
  detailedUser,
  collapsed,
  practiceArea,
  tutorDisplay = false,
}) => {
  const { subscriptionAlert, triggerAlert } = useSubscriptionAlert();
  const handleTestClick = useLaunchTest(triggerAlert);
  const handleTopicClick = useLaunchPracticeModule(triggerAlert);
  const { user } = UserAuth();
  const navigate = useNavigate();

  const handleVocabClick = (deck) => {
    navigate("/vocab", { state: { deck } });
  };

  const disabledLinks = useDisableLinks(user, detailedUser);
  const displayTestTime = useDisplayTestTime(detailedUser);
  const displayTopicTime = useDisplayTopicTime(detailedUser);

  const { practiceTests, practiceTopics, vocabDecks } =
    detailedUser.tutorAssignedWork;

  const chooseContentBasedOnPracticeArea = () => {
    if (practiceArea === "Tests") {
      return (
        <PracticeTestsTable
          tests={practiceTests}
          handleTestClick={handleTestClick}
          displayTestTime={displayTestTime}
          tutorDisplay={tutorDisplay}
        />
      );
    } else if (practiceArea === "Topics") {
      return (
        <PracticeTopicsTable
          topics={practiceTopics}
          handleTopicClick={handleTopicClick}
          displayTopicTime={displayTopicTime}
          tutorDisplay={tutorDisplay}
        />
      );
    } else if (practiceArea === "Vocab") {
      return (
        <VocabDecksTable
          decks={vocabDecks}
          handleVocabClick={handleVocabClick}
          tutorDisplay={tutorDisplay}
        />
      );
    } else {
      return (
        <>
          <PracticeTestsTable
            tests={practiceTests}
            handleTestClick={handleTestClick}
            displayTestTime={displayTestTime}
            tutorDisplay={tutorDisplay}
          />
          <PracticeTopicsTable
            topics={practiceTopics}
            handleTopicClick={handleTopicClick}
            displayTopicTime={displayTopicTime}
            tutorDisplay={tutorDisplay}
          />
          <VocabDecksTable
            decks={vocabDecks}
            handleVocabClick={handleVocabClick}
            tutorDisplay={tutorDisplay}
          />
        </>
      );
    }
  };

  return (
    <Card
      className="rounded-4 w-100 bg-color-white overflow-hidden mx-4"
      style={{ maxWidth: "550px", maxHeight: collapsed ? "15rem" : "24rem" }}
    >
      <Card.Header
        className="border-bottom-0 my-0 pb-0"
        style={{ backgroundColor: "white" }}
      >
        <Row className="d-flex align-middle align-items-center justify-content-center">
          <h2 className="fs-1 p-0 m-0 mb-0 text-center purple-text amatic-font font-weight-very-bold">
            {detailedUser?.firstName
              ? `${detailedUser?.firstName}'s To-Do List`
              : "To-Do List"}
          </h2>
          <div className="purple-line-row-thin mb-2"></div>
        </Row>
      </Card.Header>
      <Card.Body className="my-0 pt-0 overflow-scroll">
        {subscriptionAlert ? (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
              exit: { opacity: 0 },
            }}
            transition={{ duration: 0.5 }}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <div
              className="alert alert-danger"
              role="alert"
              style={{ maxWidth: "30rem" }}
            >
              You must have an active subscription to access those materials!
            </div>
            <div className="text-center">
              <a
                href="/subscribe"
                className="btn purple-button text-white hover-enlarge-more"
              >
                Subscribe
              </a>
            </div>
          </motion.div>
        ) : (
          <>{chooseContentBasedOnPracticeArea()}</>
        )}
      </Card.Body>
    </Card>
  );
};

export default TutorAssignmentsCard;
