import axios from 'axios';
import { app } from '../context/AuthContext';

const mongoApi = axios.create({
    baseURL: process.env.REACT_APP_MONGODB_BASE_URL,
});

const getAuthConfig = async () => {
    if (!app.currentUser) {
        return null;
    }

    await app.currentUser.refreshAccessToken();
    const accessToken = app.currentUser.accessToken;

    return {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        },
    };
};

// This updates a vocabulary word with token authentication. Tested
export const updateWord = async (updatedWord) => {
    const config = await getAuthConfig();
    const res = await mongoApi.post('updatevocabword', updatedWord, config);
    return res.data;
};

// This retrieves a vocabulary word by name with token authentication. Tested
export const getWordByName = async (name) => {
    if (name) {
        const config = await getAuthConfig();
        config.params = {
            name: name,
        };
        const res = await mongoApi.get('getwordbyname', config);
        return res.data.body;
    }
};

// This retrieves all vocabulary words with token authentication. Tested
export const getAllWords = async () => {
    const config = await getAuthConfig();
    const res = await mongoApi.get('getallwords', config);
    return res.data;
};


export const addWordToVocabDeck = async (deckName, word) => {
    const paylooadData = {
        deckName: deckName,
        word: word,
    };
    const config = await getAuthConfig();
    const res = await mongoApi.post('addwordtovocabdeck', paylooadData, config);
    // console.log(res.data);
    return res.data;
};

export const getDeckForSpecificWord = async (word) => {
    const paylooadData = {
        word: word,
    };
    const config = await getAuthConfig();
    const res = await mongoApi.post('getdeckforspecificword', paylooadData, config);
    return res.data.deckNames;
};

export const removeWordFromVocabDeck = async (deckName, word) => {
    const paylooadData = {
        deckName: deckName,
        word: word,
    };
    const config = await getAuthConfig();
    const res = await mongoApi.post('removewordfromvocabdeck', paylooadData, config);

    return res.data;
};

export const getAllVocabDecks = async () => {
    const config = await getAuthConfig();
    const res = await mongoApi.get('getallvocabdecks', config);
    
    return res.data;
};

export const addVocabDeckToUser = async (deck, email) => {
    const paylooadData = {
        deck: deck,
        email: email,
    };
    const config = await getAuthConfig();
    const res = await mongoApi.post('addvocabdecktouser', paylooadData, config);
    return res.data;
};

export const getUserVocabDecksByEmail = async (email) => {
    const paylooadData = {
        email: email,
    };
    const config = await getAuthConfig();
    const res = await mongoApi.post('getuservocabdecksbyemail', paylooadData, config);
    return res.data.decks;
};

export const updateUserVocabDeck = async (updatedDeck) => {
    const paylooadData = {
        updatedDeck: updatedDeck,
    };
    const config = await getAuthConfig();
    const res = await mongoApi.post('updateuservocabdeckbyid', paylooadData, config);
    return res.data;
};

export const getUserVocabDeckById = async (id) => {
    const config = await getAuthConfig();
    config.params = {
        id: id,
    };
    const res = await mongoApi.get('getuservocabdeckbyid', config);
    return JSON.parse(res.data.body);
}
