import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { UserAuth } from '../context/AuthContext';
import { getDetailedUser } from '../api/userApi';
import { det } from 'mathjs';

const useTotalSATTimeRemaining = (activeTest) => {
  const { user } = UserAuth();
  const [totalTimeRemaining, setTotalTimeRemaining] = useState(0);
  const [formattedTimeRemaining, setFormattedTimeRemaining] = useState('');

  const { isLoading: isLoadingDetailedUser, refetch: refreshCurrentUser, data: detailedUser, isSuccess } = useQuery(
    ["detailed-user", user?.email], () => getDetailedUser(user?.email));

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    let formattedTime = '';

    if (hours > 0) {
      formattedTime += `${hours} hr${hours > 1 ? 's' : ''}, `;
    }

    if (minutes > 0) {
      formattedTime += `${minutes} min${minutes > 1 ? 's' : ''}`;
    }

    if (hours === 0 && seconds > 0) {
      formattedTime += `, ${seconds} sec${seconds > 1 ? 's' : ''}`;
    }

    return formattedTime;
  };

  useEffect(() => {
    const calculateTotalTimeRemaining = () => {
      if (activeTest) {
        const currentTest = activeTest[0];
        const currentSectionNumber = currentTest.currentSectionNumber;
        const timeElapsed = currentTest.timeElapsed;

        let sectionTimeLimit = 0;
        let remainingTimeForCurrentSection = 0;
        let totalTimeForRemainingSections = 0;

        if (currentSectionNumber === 1 || currentSectionNumber === 2) {
          sectionTimeLimit = 32 * 60; // 32 minutes in seconds
        } else if (currentSectionNumber === 3 || currentSectionNumber === 4) {
          sectionTimeLimit = 35 * 60; // 35 minutes in seconds
        }

        remainingTimeForCurrentSection = sectionTimeLimit - timeElapsed;

        // Calculate the total time for remaining sections
        for (let section = currentSectionNumber + 1; section <= 4; section++) {
          if (section === 1 || section === 2) {
            totalTimeForRemainingSections += 32 * 60;
          } else if (section === 3 || section === 4) {
            totalTimeForRemainingSections += 35 * 60;
          }
        }

        const totalTimeRemaining = remainingTimeForCurrentSection + totalTimeForRemainingSections;
        if (detailedUser?.extraTimeStatus === "Extended Time"){
          setTotalTimeRemaining(totalTimeRemaining * 1.5);
        }
        if (detailedUser?.extraTimeStatus === "Double Time"){
          setTotalTimeRemaining(totalTimeRemaining * 2);
        }
        setTotalTimeRemaining(totalTimeRemaining);
        setFormattedTimeRemaining(formatTime(totalTimeRemaining));
      }
    };

    calculateTotalTimeRemaining();
  }, [activeTest]);

  return { totalTimeRemaining, formattedTimeRemaining };
};

export default useTotalSATTimeRemaining;
