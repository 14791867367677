import React from "react";
import { RotatingLines } from "react-loader-spinner";

const LoadingSpinner = () => {
  return (
    <div>
      <h1 className="text-center amatic-font font-weight-very-bold display-2 mt-5">
        Resolve
      </h1>
      <div className="d-flex justify-content-center mt-5">
        <RotatingLines
          strokeColor="#063213"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </div>
    </div>
  );
};

export default LoadingSpinner;
