import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';
import Signin from '../Signin';
import Welcome from '../Welcome';
import { useUserSubscriptionStatus } from '../../hooks/useUserSubscriptionStatus';
import StartPage from '../StartPage';
import { useQuery } from 'react-query';
import { getDetailedUser } from '../../api/userApi';

const TutorProtectedRoute = ({ children }) => {

    const {user} = UserAuth();

    const { isLoading: isLoadingDetailedUser, refetch: refetchDetailedUser, data: detailedUser, isSuccess } = useQuery(
        ["detailed-user", user?.email], () => getDetailedUser(user?.email));

    const isActiveSubscription = useUserSubscriptionStatus();

//     if (!user) {
//         return <Navigate to='/' />
//     }
//   return children;

    return (((detailedUser?.role === "tutor" && isActiveSubscription) || detailedUser?.role === "admin") ?  
            children : <StartPage /> );



};

export default TutorProtectedRoute;