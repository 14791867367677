import React from "react";
import { Table } from "react-bootstrap";
import useHasTestBeenCompletedSinceAssigned from "../../../hooks/test-info/useHasTestBeenCompletedSinceAssigned";

const PracticeTestsTable = ({
  tests,
  handleTestClick,
  displayTestTime,
  tutorDisplay = false,
}) => {
  const {
    checkCompletion: hasTestBeenCompleted,
    isLoading,
    isError,
    error,
  } = useHasTestBeenCompletedSinceAssigned();

  if (!tutorDisplay) {
    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error: {error.message}</div>;
  }

  if (!tests) return <div>No tests available.</div>;

  // Sort tests so that completed tests are at the bottom
  const sortedTests = tests?.sort((a, b) => {
    if (!tutorDisplay) {
      const aCompleted = hasTestBeenCompleted(a.testName, a.assignedDate);
      const bCompleted = hasTestBeenCompleted(b.testName, b.assignedDate);
      if (aCompleted && !bCompleted) return 1;
      if (!aCompleted && bCompleted) return -1;
    }
    return a.testName.localeCompare(b.testName);
  });

  if (!sortedTests?.length) return null;

  return (
    <div className="text-black text-center">
      <h3 className="fs-6 p-0 m-0 mb-0 text-center text-white bg-color-black font-weight-very-bold">
        Practice Tests
      </h3>
      <Table hover size="sm" className="text-black">
        <tbody>
          {sortedTests.map((test) => {
            const isCompleted = tutorDisplay
              ? false
              : hasTestBeenCompleted(test.testName, test.assignedDate);
            return (
              <tr
                key={test.testName}
                className={`${
                  isCompleted ? "text-green" : "hover-enlarge-more"
                }`}
                onClick={
                  !isCompleted ? () => handleTestClick(test.testName) : null
                }
              >
                <td className="text-start">
                  {isCompleted && <span>&#x2713; </span>}
                  {test.testName}
                </td>
                <td className="text-end">
                  {isCompleted ? "Completed" : displayTestTime(test.testName)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default PracticeTestsTable;
