import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import { useNavigate } from 'react-router-dom';
import './UnderConstructionModal.css';

function UnderConstructionModal(props) {
    const {show} = props;
  const [timeLeft, setTimeLeft] = useState(null);
  const countDownDate = new Date("April 15, 2023 00:00:00").getTime();
  const navigate = useNavigate();
  
  useEffect(() => {
    
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  
  return (
    <Modal show={show} className="modal-lg d-flex justify-content-center align-items-center align-content-center beta-launch-modal">
      <Modal.Header className='bg-color-black'>
        <Modal.Title>
            <div className='d-flex justify-content-center text-center'>
                <h1 className='text-center text-white px-5 text-nowrap pt-2'>The Beta Launch Begins In...</h1>
                
            </div>
            
        </Modal.Title>
        
      </Modal.Header>
      <Modal.Body className=' m-0 p-0 pt-0'>
      <div className='green-line-row mt-0 pt-0'></div>
      <Row className='d-flex justify-content-center mt-5 mb-0'>
        <Col md={"auto"} className="mx-3 ms-4">
            <h5>
                Days
            </h5>
        </Col>
        <Col md={"auto"} className="mx-3 ms-4 ps-4">
            <h5>
                Hours
            </h5>
        </Col>
        <Col md={"auto"} className="mx-3">
            <h5>
                Minutes
            </h5>
        </Col>
        <Col md={"auto"} className="mx-3 ms-2">
            <h5>
                Seconds
            </h5>
        </Col>
      </Row>
      <Row className='mx-4 text-black mt-0 mb-0'>
            <FlipClockCountdown
                to={countDownDate + 24 * 3600 * 1000 + 5000}
                labels={['DAYS', 'HOURS', 'MINUTES', 'SECONDS']}
                showLabels={true}
                labelStyle={{ fontSize: 50, fontWeight: 500, textTransform: 'uppercase' }}
                digitBlockStyle={{ width: 40, height: 60, fontSize: 30 }}
                dividerStyle={{ color: 'white', height: 1 }}
                separatorStyle={{ color: 'black', size: '6px' }}
                duration={0.5}
                className='text-center d-flex justify-content-center'>
      </FlipClockCountdown>

      </Row>
      <Row className='text-center'>
        <Col>
        <h3>
            Stop back here soon for your Beta invite!
        </h3>
  
        </Col>
      </Row>
      
       
      </Modal.Body>
      <Modal.Footer>
        <Button className='green-button' onClick={() => {navigate('/tests')}}>
          Login for Alpha Users
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UnderConstructionModal;
