import React from 'react';
import { Row, Col, Button, Offcanvas } from 'react-bootstrap';

const AdminPanel = ({handleClose, handleNavigateClick, handleShow, show}) => {
  return (
    <div> 
        
    <Col
        xs={"auto"}
        md={2}
        lg={1} className="ps-3">
        <Button className='green-button hover-enlarge-most' size="sm" onClick={handleShow} aria-label="Show Admin Tools" style={{zIndex: "1000"}}>
            {/* For XS screens */}
            <span className='d-block d-sm-none'>A</span>
            {/* For SM screens and larger */}
            <span className='d-none d-sm-block'>Admin</span>
        </Button>
    </Col>
    <Offcanvas show={show} onHide={handleClose} placement='bottom'>
        <Offcanvas.Header closeButton className='m-0 pb-0'>
            <Offcanvas.Title className='text-bold'>Admin Tools</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='mt-0 pt-0'>
            <Row>
                <Col>
                    <h5 className='m-2'>Users</h5>
                    <div className='green-line-row-thin'></div>
                    <Button className='green-button m-2 w-100' onClick={() => handleNavigateClick('/admintools')}>Manage Users</Button>
                    <Button className='green-button m-2 w-100' onClick={() => handleNavigateClick('/managestudents')}>Manage Students</Button>
                </Col>
                <Col>
                    <h5 className='m-2'>Tests</h5>
                    <div className='green-line-row-thin'></div>
                    <Button className='green-button m-2 w-100' onClick={() => handleNavigateClick('/testscales')}>Edit Test Scales</Button>
                    <Button className='green-button m-2 w-100' onClick={() => handleNavigateClick('/readtestscales')}>View Answers/Scales </Button>
                    <Button className='green-button m-2 w-100' onClick={() => handleNavigateClick('/editdigitalsat')}>Create Digital SAT</Button>
                    <Button className='green-button m-2 w-100' onClick={() => handleNavigateClick('/viewalldigitalsat')}>View All Digital SAT</Button>
                </Col>
                {/* <Col>
                    <h5 className='m-2'>Schools</h5>
                    <div className='green-line-row-thin'></div>
                    <Button className='green-button m-2 w-100' onClick={() => handleNavigateClick('/inputschool')}>Input School </Button>
                    <Button className='green-button m-2 w-100' onClick={() => handleNavigateClick('/viewallschools')}>View All Schools </Button>
                </Col> */}
                <Col>
                    <h5 className='m-2'>Blog</h5>
                    <div className='green-line-row-thin'></div>
                    <Button className='green-button m-2 w-100' onClick={() => handleNavigateClick('/blog-entry-editor')}>Create Blog Post</Button>
                    <Button className='green-button m-2 w-100' onClick={() => handleNavigateClick('/all-blog-posts')}>View All Blog Posts</Button>
                </Col>
                <Col>
                    <h5 className='m-2'>Problems</h5>
                    <div className='green-line-row-thin'></div>
                    <Button className='green-button m-2 w-100' onClick={() => handleNavigateClick('/problemtypes')}>Problem Types</Button>
                    <Button className='green-button m-2 w-100' onClick={() => handleNavigateClick('/blacklisttopics')}>Topic Blacklist</Button>
                    <Button className='green-button m-2 w-100' onClick={() => handleNavigateClick('/problemstatistics')}>Incorrect Freq.</Button>
                    <Button className='green-button m-2 w-100' onClick={() => handleNavigateClick('/vocabwordsused')}>Used Vocab Words</Button>
                </Col>
                <Col>
                    <h5 className='m-2'>Learning Modules</h5>
                    <div className='green-line-row-thin'></div>
                    <Button className='green-button m-2 w-100' onClick={() => handleNavigateClick('/editlearningmodule')}>Create/Edit Module</Button>
                    <Button className='green-button m-2 w-100' onClick={() => handleNavigateClick('/viewalllearningmodules')}>View All Modules</Button>
                </Col>
                <Col>
                    <h5 className='m-2'>Vocabulary</h5>
                    <div className='green-line-row-thin'></div>
                    <Button className='green-button m-2 w-100' onClick={() => handleNavigateClick('/viewsinglevocabcard')}>View Single Vocabulary Word</Button>
                    <Button className='green-button m-2 w-100' onClick={() => handleNavigateClick('/viewallvocab')}>View All Vocabulary Words</Button>
                    <Button className='green-button m-2 w-100' onClick={() => handleNavigateClick('/vocab')}>View Decks</Button>
                </Col>
            </Row>
        </Offcanvas.Body>
    </Offcanvas>
</div>
  )
}

export default AdminPanel