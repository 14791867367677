import axios from 'axios';
import { app } from '../context/AuthContext';

const mongoApi = axios.create({
    baseURL: process.env.REACT_APP_MONGODB_BASE_URL,
});

const getAuthConfig = async () => {
    if (!app.currentUser) {
        return null;
    }

    await app.currentUser.refreshAccessToken();
    const accessToken = app.currentUser.accessToken;

    return {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        },
    };
};

// This retrieves all learning modules with token authentication. Tested.
export const getAllLearningModules = async () => {
    try {
      const config = await getAuthConfig();
      const res = await mongoApi.get('getalllearningmodules', config);
      const sortedModules = res.data.sort((a, b) => {
        if (a.percentOfAllQuestions === undefined) return 1;
        if (b.percentOfAllQuestions === undefined) return -1;
        return b.percentOfAllQuestions - a.percentOfAllQuestions;
      });
      return sortedModules;
    } catch (error) {
      console.error('Error fetching learning modules:', error);
      throw error; // Rethrow the error after logging it
    }
  };
  

// This retrieves a learning module by name with token authentication. Tested.
export const getLearningModuleByName = async (topicName) => {
    const config = await getAuthConfig();
    config.params = {
        moduleName: topicName,
    };
    const res = await mongoApi.get('getlearningmodulebyname', config);
    return res.data.body[0];
};

// This creates a new learning module with token authentication. Tested.
export const createLearningModule = async (module) => {
    const config = await getAuthConfig();
    const res = await mongoApi.post('createlearningmodule', module, config);
    return res.data;
};
