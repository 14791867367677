import axios from 'axios';
import { app } from '../context/AuthContext';

const mongoApi = axios.create({
    baseURL: process.env.REACT_APP_MONGODB_BASE_URL,
});

const getAuthConfig = async () => {
    if (!app.currentUser) {
        return null;
    }

    await app.currentUser.refreshAccessToken();
    const accessToken = app.currentUser.accessToken;

    return {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        },
    };
};

//Function to edit tutor paid subscription
export const editTutorSubscription = async (subscription) => {
    const config = await getAuthConfig();
    const res = await mongoApi.post('edittutorpaidsubscription', subscription, config);
    return res.data;
};

//Function to get all tutor paid subscriptions by email.
export const getTutorSubscriptionsByEmail = async (email) => {
    const config = await getAuthConfig();
    config.params = {
        email: email,
    };
    const res = await mongoApi.get('gettutorpaidsubscriptionsbyemail', config);
    return res.data.documents;
};

//Function to get all tutor paid subscriptions by email.
export const getTutorSubscriptionsByStudentEmail = async (email) => {
    const config = await getAuthConfig();
    config.params = {
        email: email,
    };
  
    const res = await mongoApi.get('gettutorpaidsubscriptionsbystudentemail', config);
   
    return res.data.document;
};