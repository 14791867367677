import React from 'react';
import { motion } from 'framer-motion';
import { Row, Col, Button, Card } from 'react-bootstrap';

const AccountDropDownPane = ({ dropdownVariants, accountDropdownOpen, accountMenu, handleNavigateClick, handleLogout, firstAccountMenuElement }) => {

  function ContactButton() {
    const composeEmail = () => {
      const emailAddress = 'info@resolveprep.com';
      window.location.href = `mailto:${emailAddress}`;
    };

    return (
      <Button
        variant="link"
        className='fs-5 text-decoration-none hover-enlarge text-black p-0 m-0 text-nowrap hover-bold'
        onClick={composeEmail}
        disabled={false}
        aria-disabled={false}
        role="menuitem"
      >
        Contact Us
      </Button>
    );
  }

  return (
    <motion.div
      variants={dropdownVariants}
      initial="closed"
      animate={accountDropdownOpen ? "open" : "closed"}
    >
      <nav aria-label="Account Menu navigation">
        <Row className="account-menu-dropdown mt-0 ps-5">
          <Col ref={accountMenu} md={{ span: 6, offset: 5 }} className="mt-3">
            <Card className='account-info-dropdown-card add-shadow'>
              <Row>
                <Col className='mt-2 ms-3'>
                  <Button variant="link" className='fs-3 text-decoration-none hover-enlarge text-black p-0 m-0 hover-bold' onClick={() => handleNavigateClick('/testhistory')}
                    role="menuitem" ref={firstAccountMenuElement} >
                    Records
                  </Button>
                  <div className='green-line-row mt-0 mb-2'></div>
                  <ul className='ps-0 ms-3'>
                    <li>
                      <Button variant="link" className='fs-5 text-decoration-none hover-enlarge text-black p-0 m-0 hover-bold' onClick={() => handleNavigateClick('/testhistory')} role="menuitem">
                        Tests
                      </Button>
                    </li>
                    <li>
                      <Button variant="link" className='fs-5 text-decoration-none hover-enlarge text-black p-0 m-0 hover-bold' onClick={() => handleNavigateClick('/testhistory')} role="menuitem">
                        Review
                      </Button>
                    </li>
                  </ul>

                </Col>
                <Col className='mt-2 pe-4'>
                  <Button variant="link" className='fs-3 text-decoration-none hover-enlarge text-black p-0 m-0 hover-bold' onClick={() => handleNavigateClick('/account')} role="menuitem">
                    Account
                  </Button>
                  <div className='blue-line-row mt-0 mb-2'></div>
                  <ul className='ps-0 ms-3'>
                    <li>
                      <Button variant="link" className='fs-5 text-decoration-none hover-enlarge text-black p-0 m-0 hover-bold' onClick={() => handleNavigateClick('/account')} role="menuitem">
                        Profile
                      </Button>
                    </li>

                  </ul>
                </Col>
              </Row>
              <Row>
                <Col className='mt-2 ms-3'>
                  <Button disabled variant="link" className='fs-3 text-decoration-none hover-enlarge text-black p-0 m-0 hover-bold' onClick={() => handleNavigateClick('/colleges')} role="menuitem">
                    Colleges
                  </Button>
                  <div className='purple-line-row mt-0 mb-2'></div>
                  <ul className='ps-0 ms-3'>
                    <li>
                      <Button variant="link" disabled className='fs-5 text-decoration-none hover-enlarge text-black p-0 m-0 hover-bold' onClick={() => handleNavigateClick('/colleges')} role="menuitem">
                        Colleges
                      </Button>
                    </li>
                    <li>
                      <Button variant="link" disabled className='fs-5 text-decoration-none hover-enlarge text-black p-0 m-0 hover-bold' onClick={() => handleNavigateClick('/thepath')} role="menuitem">
                        Checklist
                      </Button>
                    </li>
                  </ul>

                </Col>
                <Col className='mt-2 pe-4'>
                  <Button variant="link" disabled aria-disabled="true" className='fs-3 text-decoration-none hover-enlarge text-black p-0 m-0' onClick={() => handleNavigateClick('/account')} role="menuitem">
                    Help
                  </Button>
                  <div className='pink-line-row mt-0 mb-2'></div>
                  <ul className='ps-0 ms-3'>
                    <li>
                      <div className='fs-5 disabled-link'>Tour</div>
                    </li>
                    <li>
                      <ContactButton />
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Button size="lg" className='account-panel-sign-out mt-4 rounded-pill ms-auto me-5' onClick={() => handleLogout()}>
                  Sign Out
                </Button>
              </Row>

            </Card>
          </Col>
        </Row>
      </nav>
    </motion.div>
  )
}

export default AccountDropDownPane;
