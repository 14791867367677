import React from "react";
import { Row, Col, Button, Card, Stack, Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const TutorPanel = ({ handleClose, handleShow, show, handleNavigateClick }) => {
  const navigate = useNavigate();

  //navigate to "/managestudents" page... bypassing the offCanvas
  const handleClick = (path) => {
    console.log("clicked");
    navigate(path);
  };

  return (
    <div>
      <Col xs={"auto"} md={2} lg={1} className="ps-2">
        <Button
          className="purple-button hover-enlarge hover--pointer"
          size="sm"
          onClick={() => handleClick("/managestudents")}
          style={{ zIndex: 9999 }}
        >
          {/* For XS screens */}
          <span className="d-block d-sm-none">T</span>
          {/* For SM screens and larger */}
          <span className="d-none d-sm-block">Tutor Tools</span>
        </Button>
      </Col>
      <Offcanvas show={show} onHide={handleClose} placement="bottom">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Tutor Tools</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            className="green-button p-3 m-3"
            onClick={() => handleNavigateClick("/managestudents")}
          >
            Manage Students
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default TutorPanel;
