import { useMemo } from 'react';

const useMostRecentTests = (detailedUser) => {
    return useMemo(() => {
        if (!detailedUser) return [];

        const tempArray = [];

        detailedUser?.digitalTests?.forEach((test) => {
            const testStatus = test?.[0]?.testStatus;
            if (testStatus === "Completed") {
                const testName = test[0].testName;
                const testDate = new Date(test[0].testDate);
          
                const existingTestIndex = tempArray.findIndex(
                  (item) => item[0].testName === testName
                );
          
                if (existingTestIndex === -1 || testDate > new Date(tempArray[existingTestIndex][0].testDate)) {
                  if (existingTestIndex !== -1) {
                    tempArray.splice(existingTestIndex, 1);
                  }
                  tempArray.push(test);
                }
            }
        });

        return tempArray;
    }, [detailedUser]);
};

export default useMostRecentTests;
