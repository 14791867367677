import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Card, Form, Alert, Button, Container, Image } from 'react-bootstrap';
import { UserAuth } from '../context/AuthContext';
import StartPageHeader from './StartPageHeader';
import "./Signin.css";
import logo from './media/hand-pencil-large.svg';

const Signin = () => {

  const {signIn} = UserAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      setError('');
      await signIn(email, password);
      navigate('/start');
      setLoading(true);
    } catch (e) {
      setError("Failed to log in. Check your password. If you don't already have an account, try the Sign Up page.");
    }
    setLoading(false);
  }

  return (
    <div>
      <StartPageHeader />
      <div className='black-line-row'></div>
      <Container className='d-flex p-4 justify-content-center align-items-center main-sign-in-page pb-5'>
        <div className="w-100" style={{maxWidth: "450px"}}>
          <Card className="mw-100 p-3 mb-5 sign-in-card">
            <Card.Header className="d-flex justify-content-center sign-in-card-header">
              <div className="circular-logo-container">
                <Image src={logo} alt="company logo" className="circular-logo-image p-2" aria-label="Company logo"/>
              </div>
            </Card.Header>
            <Card.Body>
              <h1 className='text-center mb-2'>Sign In</h1>
              {error && <Alert variant="danger">{error}</Alert> }
            </Card.Body>
            <Form className=" px-2" onSubmit={handleSubmit}>
              <Form.Group id="email" className="my-2">
                <Form.Label><h5>Email Address</h5></Form.Label>
                <Form.Control type="email" aria-label="Enter Email Address" onChange={(e) => setEmail(e.target.value?.toLowerCase())} required/>
              </Form.Group>
              <Form.Group id="password" className="mt-3">
                <Form.Label><h5>Password</h5></Form.Label>
                <Form.Control type="password" aria-label="Enter Password" onChange={(e) => setPassword(e.target?.value)} required/>
                <h6 className="mt-4">
                  <Link to="/passwordreset" aria-label="Forgot password">
                    Forgot password?
                  </Link>
                </h6>
              </Form.Group>
              <Button disabled={loading} size="lg" className="w-100 mt-5 sign-in-button" type="Submit" aria-label="Sign In">Sign In</Button>
            </Form>
            <div className="w-100 text-center mt-2">
              <h5 className="mt-3">
                Don't have an account?
                <Link to="/signup" aria-label="Sign up">Sign up.</Link>
              </h5>
            </div>
          </Card>
        </div>
      </Container>
    </div>
  )
}

export default Signin
