import { useQuery } from "react-query";
import { getAllTests } from "../../api/digitalSATApi";

const useGetAllTests = () => {
  const {
    data: allDigitalSat,
    isLoading: isLoadingAllDigitalSat,
    isError: isErrorAllDigitalSat,
  } = useQuery("all-digital-sat-tests", getAllTests, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
  return { allDigitalSat, isLoadingAllDigitalSat, isErrorAllDigitalSat };
};

export default useGetAllTests;
