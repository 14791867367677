import axios from "axios";
import { app } from "../context/AuthContext";
import { sub } from "date-fns";

const mongoApi = axios.create({
  baseURL: process.env.REACT_APP_MONGODB_BASE_URL,
});

//using serverless. Backend locked down with token AUTH
export const getAllUsers = async () => {
  if (!app.currentUser) {
    return null;
  }

  // Refresh the access token and get it
  await app.currentUser.refreshAccessToken();
  const accessToken = app.currentUser.accessToken;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const res = await mongoApi.get("readallusers", config);
  return res.data;
};

//using serverless. Backend locked down with token AUTH
export const getDetailedUser = async (email) => {
  if (email) {
    if (!app.currentUser) {
      return null;
    }

    // Refresh the access token and get it
    await app.currentUser.refreshAccessToken();
    const accessToken = app.currentUser.accessToken;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        emailAddress: email,
      },
    };

    const res = await mongoApi.get("readuser", config);

    return res.data;
  }
};

//using serverless. Backend locked down with token AUTH
export const updateDetailedUser = async (updatedUser) => {
  if (!app.currentUser) {
    return null;
  }

  // Refresh the access token and get it
  await app.currentUser.refreshAccessToken();
  const accessToken = app.currentUser.accessToken;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const res = await mongoApi.post("updateuser", updatedUser, config);
    return res.data;
  } catch (error) {
    throw error;
  }
};

//using serverless. Backend locked down with AUTH. I shouldn't have had to make this function because updateDetailedUser should have worked. But it didn't and I spent 3 hours on it.
export const updateTestsArray = async (updatedUser) => {
  if (!app.currentUser) {
    return null;
  }

  // Refresh the access token and get it
  await app.currentUser.refreshAccessToken();
  const accessToken = app.currentUser.accessToken;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const res = await mongoApi.post("updatetestsarray", updatedUser, config);
    return res.data;
  } catch (error) {
    throw error;
  }
};

//using serverless. Backend locked down with AUTH.  I shouldn't have had to make this function because updateDetailedUser should have worked. But it didn't and I spent 3 hours on it.
export const updateDigitalTestsArray = async (updatedUser) => {
  if (!app.currentUser) {
    return null;
  }

  // Refresh the access token and get it
  await app.currentUser.refreshAccessToken();
  const accessToken = app.currentUser.accessToken;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const res = await mongoApi.post(
      "updatedigitaltestsarray",
      updatedUser,
      config
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

// using serverless. Intentionally not locked down with AUTH. This is used by the signup page to create a new user.
export const createNewUser = async (newUser) => {
  try {
    const res = await mongoApi.post("createuser", newUser);
    return res.data;
  } catch (error) {
    console.error("An error occurred while creating a new user:", error);
    // handle the error or re-throw as necessary
    throw error;
  }
};

//using serverless. Backend locked down with Auth.  updates practice modules:
export const updatePracticeModules = async (updatedUser) => {
  if (!app.currentUser) {
    return null;
  }

  // Refresh the access token and get it
  await app.currentUser.refreshAccessToken();
  const accessToken = app.currentUser.accessToken;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const res = await mongoApi.post(
      "updatepracticemodulesarray",
      updatedUser,
      config
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

//using serverless. Backend locked down with token AUTH. reads all the students of a particular tutor
export const readStudentsByTutorEmail = async (tutorEmail) => {
  if (!app.currentUser) {
    return null;
  }

  // Refresh the access token and get it
  await app.currentUser.refreshAccessToken();
  const accessToken = app.currentUser.accessToken;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      tutorEmail: tutorEmail,
    },
  };

  try {
    const res = await mongoApi.get("readstudentsbytutoremail", config);
    return res.data.body;
  } catch (error) {
    throw error;
  }
};

// Create a Stripe Checkout Session on the serverless backend
export const createCheckoutSession = async () => {
  if (!app.currentUser) {
    return null;
  }
  // Refresh the access token and get it
  await app.currentUser.refreshAccessToken();
  const accessToken = app.currentUser.accessToken;
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const res = await mongoApi.post("createcheckoutsession", null, config);
    return res.data;
  } catch (error) {
    throw error;
  }
};

//using serverless. Backend locked down with token AUTH
export const getSubscriptionDetails = async (subscriptionId) => {
  if (subscriptionId) {
    if (!app.currentUser) {
      return null;
    }

    // Refresh the access token and get it
    await app.currentUser.refreshAccessToken();
    const accessToken = app.currentUser.accessToken;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        subscriptionId: subscriptionId,
      },
    };

    const res = await mongoApi.get("getsubscriptiondetails", config);

    return res.data.body;
  }
};

//using serverless. Backend locked down with token AUTH
export const cancelStripeSubscription = async (subscriptionId) => {
  if (subscriptionId) {
    if (!app.currentUser) {
      return null;
    }

    // Refresh the access token and get it
    await app.currentUser.refreshAccessToken();
    const accessToken = app.currentUser.accessToken;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        subscriptionId: subscriptionId,
      },
    };

    const res = await mongoApi.get("cancelstripesubscription", config);

    return res.data.body;
  }
};

//gets all wrong digital sat questions grouped by frequency
export const getDigitalSatWrongByFrequency = async () => {
  // Refresh the access token and get it
  await app.currentUser.refreshAccessToken();
  const accessToken = app.currentUser.accessToken;
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const res = await mongoApi.get("getwrongquestionsbyfrequency", config);
  return res.data;
};

//gets all wrong digital sat questions grouped by frequency
export const getPracticeModulesWrongByFrequency = async () => {
  // Refresh the access token and get it
  await app.currentUser.refreshAccessToken();
  const accessToken = app.currentUser.accessToken;
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const res = await mongoApi.get(
    "getpracticemodulewrongquestionsbyfrequency",
    config
  );
  return res.data.resultArray;
};

export const getLemonSubscriptionDetails = async (subscriptionId) => {
  if (subscriptionId) {
    if (!app.currentUser) {
      return null;
    }

    // Refresh the access token and get it
    await app.currentUser.refreshAccessToken();
    const accessToken = app.currentUser.accessToken;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        subscriptionId: subscriptionId,
      },
    };

    const res = await mongoApi.get("getlemonsubscriptiondetails", config);
    return res.data;
  }
};

export const cancelLemonSubscription = async (subscriptionId) => {
  if (subscriptionId) {
    if (!app.currentUser) {
      return null;
    }

    // Refresh the access token and get it
    await app.currentUser.refreshAccessToken();
    const accessToken = app.currentUser.accessToken;

    // Configure headers and parameters
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        subscriptionId: subscriptionId,
      },
    };

    // Make the API call to cancel the subscription
    const res = await mongoApi.get("cancellemonsubscription", config);
    return res.data.body;
  }
};

export const restartCancelledLemonSubscription = async (subscriptionId) => {
  if (subscriptionId) {
    if (!app.currentUser) {
      return null;
    }

    // Refresh the access token and get it
    await app.currentUser.refreshAccessToken();
    const accessToken = app.currentUser.accessToken;

    // Configure headers and parameters
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        subscriptionId: subscriptionId,
      },
    };

    // Make the API call to cancel the subscription
    const res = await mongoApi.get("restartcancelledlemonsubscription", config);
    return res.data.body;
  }
};

//using serverless. Backend locked down with token AUTH
export const updateTutorAssignments = async (updatedUser) => {
  if (!app.currentUser) {
    return null;
  }

  // Refresh the access token and get it
  await app.currentUser.refreshAccessToken();
  const accessToken = app.currentUser.accessToken;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const res = await mongoApi.post(
      "updatetutorassignments",
      updatedUser,
      config
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

//using serverless. Backend locked down with token AUTH
export const updateDigitalSatById = async (updatedTest) => {
  if (!app.currentUser) {
    return null;
  }

  // Refresh the access token and get it
  await app.currentUser.refreshAccessToken();
  const accessToken = app.currentUser.accessToken;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const res = await mongoApi.post(
      "updatedigitalsatbyid",
      updatedTest,
      config
    );
    // console.log("result of updateDigitalSatById: ", res);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getBluebookTestsByEmail = async (email) => {
  if (!app.currentUser) {
    return null;
  }

  // Refresh the access token and get it
  await app.currentUser.refreshAccessToken();
  const accessToken = app.currentUser.accessToken;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      email: email,
    },
  };

  try {
    const res = await mongoApi.get("getbluebooktestsbyemail", config);
    // console.log("res.data.body", JSON.parse(res.data.body).tests);
    return JSON.parse(res.data.body).tests;
  } catch (error) {
    throw error;
  }
};

export const deleteUserBluebookTest = async (test) => {
  if (!app.currentUser) {
    return null;
  }

  // Refresh the access token and get it
  await app.currentUser.refreshAccessToken();
  const accessToken = app.currentUser.accessToken;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    // console.log("test", test);
    const res = await mongoApi.post("deleteuserbluebooktest", test, config);
    // console.log("res.data.body", JSON.parse(res.data.body));
    return JSON.parse(res.data.body);
  } catch (error) {
    throw error;
  }
};

//function to remove a student from a tutor's list of students
export const removeStudentFromTutorList = async (emailAddresses) => {
  if (!app.currentUser) {
    return null;
  }

  // Refresh the access token and get it
  await app.currentUser.refreshAccessToken();
  const accessToken = app.currentUser.accessToken;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const res = await mongoApi.post(
      "removestudentfromtutorlist",
      {
        studentEmail: emailAddresses.studentEmail,
        tutorEmail: emailAddresses.tutorEmail,
      },
      config
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const verifyCaptchaToken = async (token) => {
  if (token) {
    // Configure headers and parameters
    const config = {
      headers: {},
      params: {
        token: token,
      },
    };
    // Make the API call to cancel the subscription
    const res = await mongoApi.get("verifyrecaptchawithgoogle", config);
    //console.log("res", res.data.body);
    //console.log(typeof res.data.body);
    const response = JSON.parse(res.data.body);
    //console.log("response", response);
    //console.log(typeof response);
    return response;
  }
};

//using serverless. Backend locked down with token AUTH
// export const getPaddleSubscriptionDetails = async (subscriptionId) => {

//     if (subscriptionId) {
//         if (!app.currentUser) {
//             return null;
//         }

//         // Refresh the access token and get it
//         await app.currentUser.refreshAccessToken();
//         const accessToken = app.currentUser.accessToken;
//         const config = {
//             headers: {
//                 'Authorization': `Bearer ${accessToken}`,
//             },
//             params: {
//                 subscriptionId: subscriptionId,
//             },
//         };

//         const res = await mongoApi.get('getpaddlesubscriptiondetails', config);
//         return res.data;
//     }
// };

// Using serverless. Backend locked down with token AUTH
// export const cancelPaddleSubscription = async (subscriptionId) => {

//     if (subscriptionId) {
//         if (!app.currentUser) {
//             return null;
//         }

//         // Refresh the access token and get it
//         await app.currentUser.refreshAccessToken();
//         const accessToken = app.currentUser.accessToken;

//         // Configure headers and parameters
//         const config = {
//             headers: {
//                 'Authorization': `Bearer ${accessToken}`,
//             },
//             params: {
//                 subscriptionId: subscriptionId,
//             },
//         };

//         // Make the API call to cancel the subscription
//         const res = await mongoApi.get('cancelpaddlesubscription', config);
//        console.log("the response from server for cancelPaddleSubscription was", res);
//         return res.data.body;
//     }
// };

export const getTotalUserStatistics = async () => {
  if (!app.currentUser) {
    return null;
  }

  // Refresh the access token and get it
  await app.currentUser.refreshAccessToken();
  const accessToken = app.currentUser.accessToken;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const res = await mongoApi.get("gettotaluserstatistics", config);

    return res.data;
  } catch (error) {
    throw error;
  }
};
