const useDisplayTestTime = (detailedUser) => (testName) => {
  const foundTest = detailedUser?.digitalTests?.find(
    (test) =>
      test[0].testName === testName && test[0].testStatus === "In Progress"
  );

  if (foundTest) {
    return "Resume Test";
  } else {
    return detailedUser.extraTimeStatus === "Double Time"
      ? "4 hrs, 28 min"
      : detailedUser.extraTimeStatus === "Extended Time"
      ? "3 hrs, 21 min"
      : "2 hrs, 14 min";
  }
};

export default useDisplayTestTime;
