import React from "react";
import { Table } from "react-bootstrap";
import useVocabDeckStatus from "../../../hooks/test-info/useVocabDeckStatus";

const VocabDecksTable = ({ decks, handleVocabClick, tutorDisplay = false }) => {
  const { vocabDeckStatus, isLoading, isError, error } = useVocabDeckStatus();

  if (!tutorDisplay) {
    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error: {error.message}</div>;
  }

  const sortedDecks = decks?.sort((a, b) =>
    a.deckName.localeCompare(b.deckName)
  );
  if (!sortedDecks?.length) return null;

  return (
    <div className="text-black text-center">
      <h3 className="fs-6 p-0 m-0 mb-0 text-center text-white bg-color-black font-weight-very-bold">
        Vocabulary
      </h3>
      <Table hover size="sm" className="text-black">
        <tbody>
          {sortedDecks.map((deck) => {
            const status = tutorDisplay
              ? "Start Now!"
              : vocabDeckStatus[deck.deckName] || "Start Now!";
            return (
              <tr
                key={deck.deckName}
                className={`${
                  status === "Mastered" ? "text-green" : "hover-enlarge-more"
                }`}
                onClick={() => handleVocabClick(deck)}
              >
                <td className="text-start">
                  {status === "Mastered" && <span>&#x2713; </span>}
                  {deck.deckName}
                </td>
                <td className="text-end">{status}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default VocabDecksTable;
