//First Accessibility pass: completed.

import React from 'react';
import { motion } from 'framer-motion';
import { Row, Col, Button } from 'react-bootstrap';

function DropDownNavigationPane({ dropdownVariants, dropdownIsOpen, mainMenu, handleNavigateClick, handleNavigateClickWithState, firstMainMenuElement }) {



    return (
        <motion.div
            variants={dropdownVariants}
            initial="closed"
            animate={dropdownIsOpen ? "open" : "closed"}
        >
            <Row ref={mainMenu} className="welcome-menu-dropdown mt-0 px-4" role="menu">
                <Col className='ms-auto mt-4 '>
                    <Button variant="link" className='fs-2 text-decoration-none hover-enlarge text-white p-0 m-0' onClick={() => handleNavigateClick('/tests')} role="menuitem" ref={firstMainMenuElement}>
                        Test
                    </Button>
                    <div className='green-line-row mt-2 mb-3'></div>
                    <Button variant="link" className='fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' onClick={() => handleNavigateClick('/computertests')} role="menuitem">
                        SAT
                    </Button>
                    <Button variant="link" className='fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' onClick={() => handleNavigateClick('/acttests')} role="menuitem">
                        ACT
                    </Button>

                    {/* <Button variant="link" disabled className='fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' role="menuitem">
                                GMAT
                            </Button>
                            <Button variant="link" disabled className='fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' role="menuitem">
                                GRE
                            </Button> */}
                </Col>
                <Col className='ms-auto mt-4 '>
                    <Button variant="link" className='fs-2 text-decoration-none hover-enlarge text-white p-0 m-0' onClick={() => handleNavigateClick('/learn')} role="menuitem">
                        Learn
                    </Button>
                    <div className='blue-line-row mt-2 mb-3'></div>
                    <Button variant="link" className='fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' onClick={() => handleNavigateClickWithState('/learn', 'Math')} role="menuitem">
                        Math
                    </Button>
                    <Button variant="link" className='fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' onClick={() => handleNavigateClickWithState('/learn', 'Reading')} role="menuitem">
                        Reading
                    </Button>
                    <Button variant="link" className='fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' onClick={() => handleNavigateClickWithState('/learn', 'Writing')} role="menuitem">
                        Writing
                    </Button>
                    {/* <Button variant="link" className='fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' onClick={() => handleNavigateClickWithState('/learn', 'Science')}  role="menuitem">
                                Science
                            </Button> */}
                    <br></br>
                    <Button variant="link" className='fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block text-nowrap' onClick={() => handleNavigateClick('/privatetutoring')} role="menuitem">
                        Tutoring
                    </Button>

                </Col>
                <Col className='ms-auto mt-4 '>
                    <Button variant="link" className='fs-2 text-decoration-none hover-enlarge text-white p-0 m-0' onClick={() => handleNavigateClick('/practicemodules')} role="menuitem">
                        Practice
                    </Button>

                    <div className='purple-line-row mt-2 mb-3'></div>
                    <Button variant="link" className='fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' onClick={() => handleNavigateClickWithState('/practicemodules', 'Math')} role="menuitem">
                        Math
                    </Button>
                    <Button variant="link" className='fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' onClick={() => handleNavigateClickWithState('/practicemodules', 'Reading')} role="menuitem">
                        Reading
                    </Button>
                    <Button variant="link" className='fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' onClick={() => handleNavigateClickWithState('/practicemodules', 'Writing')} role="menuitem">
                        Writing
                    </Button>


                    <br></br>
                    <Button variant="link" className='fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' onClick={() => handleNavigateClick('/vocab')} role="menuitem">
                        Vocab
                    </Button>
                </Col>
                {/* <Col className='ms-auto mt-4 '>
                            <Button  disabled variant="link" className='fs-2 text-decoration-none hover-enlarge text-white p-0 m-0' onClick={() => handleNavigateClick('/applications')} role="menuitem">
                                Applications
                            </Button>
                            <div className='pink-line-row mt-2 mb-3'></div>
                            <Button disabled  variant="link" className='fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' onClick={() => handleNavigateClick('/thepath')} role="menuitem">
                                The Path
                            </Button>
                            <Button disabled  variant="link" className='fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' onClick={() => handleNavigateClick('/colleges')} role="menuitem">
                                Colleges
                            </Button>
                            <Button disabled  variant="link" className='fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' role="menuitem">
                                Academics
                            </Button>
                            <Button disabled  variant="link" className='fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' role="menuitem">
                                Essays
                            </Button>
                            <Button disabled  variant="link" className='fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' role="menuitem">
                                Activities
                            </Button>
                        </Col> */}
                <Col className='ms-auto mt-4 '>
                    <Button variant="link" className='fs-2 text-decoration-none hover-enlarge text-white p-0 m-0' onClick={() => handleNavigateClick('/blog')} role="menuitem">
                        Blog
                    </Button>
                    <div className='orange-line-row mt-2 mb-3'></div>
                    <Row className='d-flex justify-content-start'>
                        <Col xs={12} className='d-flex justify-content-start p-0 ps-3'>
                            <Button variant="link" className='text-start fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' onClick={() => handleNavigateClick('/blog/digital-sat-start-test-prep')} role="menuitem">
                                Start Here
                            </Button>
                        </Col>
                        <Col xs={12} className='d-flex justify-content-start p-0 ps-3'>
                            <Button variant="link" className='text-start fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' onClick={() => handleNavigateClick('/blog/digital-sat-vs-act-prep-in-2024')} role="menuitem">
                                SAT vs. ACT
                            </Button>
                        </Col>

                        <Col xs={12} className='d-flex justify-content-start p-0 ps-3'>
                            <Button variant="link" className='text-start fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' onClick={() => handleNavigateClick('/blog/digital-sat-math-overview')} role="menuitem">
                                Math Info
                            </Button>
                        </Col>
                        <Col xs={12} className='d-flex justify-content-start p-0 ps-3'>
                            <Button variant="link" className='text-start fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' onClick={() => handleNavigateClick('/blog/digital-sat-verbal-overview')} role="menuitem">
                                Verbal Info
                            </Button>
                        </Col>
                        <Col xs={12} className='d-flex justify-content-start p-0 ps-3'>
                            <Button variant="link" className='text-start fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' onClick={() => handleNavigateClick('/blog/sat-calculator-guide-2024')} role="menuitem">
                                Calculator
                            </Button>
                        </Col>
                        <Col xs={12} className='d-flex justify-content-start p-0 ps-3'>
                            <Button variant="link" className='text-start fs-3 text-decoration-none hover-enlarge text-white p-0 m-0 d-block' onClick={() => handleNavigateClick('/blog/study-vocabulary-for-the-digital-sat')} role="menuitem">
                                Vocab
                            </Button>
                        </Col>
                     
                    </Row>
                </Col>

            </Row>
        </motion.div>
    )
}

export default DropDownNavigationPane