import { createContext, useEffect, useState } from "react";
import { App, Credentials } from "realm-web";
import { useContext } from "react";
import {
  updateDetailedUser,
  getDetailedUser,
  createNewUser,
} from "../api/userApi";

export const app = new App("prepsite-edwrv");

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(app.currentUser);
  const [userEmail, setUserEmail] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const [detailedUser, setDetailedUser] = useState(null);
  const [detailedUserLoading, setDetailedUserLoading] = useState(false);

  const signIn = async (email, password) => {
    const credentials = Credentials.emailPassword(email, password);
    const authedUser = await app.logIn(credentials);
    setCurrentUser(app.currentUser);
    return authedUser;
  };

  const createUser = async (
    email,
    password,
    firstName = null,
    lastName = null
  ) => {
    try {
      await app.emailPasswordAuth.registerUser({ email, password });
      setUserEmail(email);
      setUserFirstName(firstName);
      setUserLastName(lastName);
      await signIn(email, password);
      const userDetails = { emailAddress: email };
      if (firstName) userDetails.firstName = firstName;
      if (lastName) userDetails.lastName = lastName;
      return createNewUser(userDetails);
    } catch (error) {
      throw error;
    }
  };
  const fetchUser = async () => {
    if (!currentUser) return false;
    try {
      await currentUser.refreshCustomData();
      setCurrentUser(app.currentUser);
      return app.currentUser;
    } catch (error) {
      if (error.statusCode === 401) {
        // Handle token expiry
        await refreshToken();
        return fetchUser(); // Retry the original request
      } else {
        throw error;
      }
    }
  };

  const logOutUser = async () => {
    if (!currentUser) return false;
    try {
      await currentUser.logOut();
      setCurrentUser(null);
      return true;
    } catch (error) {
      throw error;
    }
  };

  const refreshToken = async () => {
    try {
      // Logic to refresh the token
      // This might vary based on how MongoDB Realm handles token refresh
      await app.currentUser.refreshAccessToken();
      setCurrentUser(app.currentUser);
    } catch (error) {
      // Handle errors during token refresh
      console.error("Error refreshing token:", error);
      throw error;
    }
  };

  const updateUserWithContext = async (updatedUser) => {
    try {
      await app.currentUser.refreshAccessToken();

      const updatedUserData = await updateDetailedUser(
        updatedUser,
        app.currentUser.accessToken
      );
      await app.currentUser.refreshCustomData();
      setCurrentUser(app.currentUser);
      return updatedUserData;
    } catch (error) {
      throw error;
    }
  };

  const getRefreshedDetailedUser = () => {
    setDetailedUserLoading(true);
    if (currentUser) {
      getDetailedUser(currentUser.profile.email).then((response) => {
        setDetailedUser(response);
        setDetailedUserLoading(false);
      });
    }
  };

  const getCurrentToken = async () => {
    try {
      await app.currentUser.refreshAccessToken();
      return app.currentUser.accessToken;
    } catch (error) {
      throw error;
    }
  };

  const resetPassword = async (email) => {
    try {
      await app.emailPasswordAuth.sendResetPasswordEmail({ email });
    } catch (error) {
      throw error;
    }
  };

  const value = {
    app,
    user: currentUser?.profile,
    setCurrentUser,
    fetchUser,
    signIn,
    createUser,
    logout: logOutUser,
    userEmail,
    updateUserWithContext,
    getCurrentToken,
    detailedUser,
    detailedUserLoading,
    resetPassword,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const UserAuth = () => {
  return useContext(UserContext);
};
