import { useCallback } from "react";
import { getDetailedUser } from "../../api/userApi";
import { useQuery } from "react-query";
import { UserAuth } from "../../context/AuthContext";
import { parseISO, isAfter, isSameDay, parse } from "date-fns";

const useHasPracticeModuleBeenCompletedSinceAssigned = () => {
  const { user } = UserAuth();

  const {
    data: detailedUser,
    isLoading,
    isError,
    error,
  } = useQuery(
    ["detailed-user", user?.email],
    () => getDetailedUser(user?.email),
    {
      enabled: !!user?.email, // Only run the query if the user is authenticated
    }
  );

  const checkCompletion = useCallback(
    (moduleName, assignedDate) => {
      if (!assignedDate || !moduleName) return false;

      const assignedDateParsed = parse(assignedDate, "MM-dd-yy", new Date());

      return detailedUser?.practiceModules?.some((module) => {
        if (
          module[0]?.problemType === moduleName &&
          module[0]?.status === "Graded"
        ) {
          const moduleDateParsed = parseISO(module[0].date);
          return (
            isSameDay(moduleDateParsed, assignedDateParsed) ||
            isAfter(moduleDateParsed, assignedDateParsed)
          );
        }
        return false;
      });
    },
    [detailedUser]
  );

  return {
    checkCompletion,
    isLoading,
    isError,
    error,
  };
};

export default useHasPracticeModuleBeenCompletedSinceAssigned;
