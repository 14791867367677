import React, {useState, useEffect} from 'react';
import { Row, Col, Container, Button, ButtonGroup, ToggleButton, Card, Table, Image } from 'react-bootstrap';
import './Welcome.css';
import Axios from 'axios';
import {UserAuth} from '../context/AuthContext';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getDetailedUser } from '../api/userApi';


const Welcome = () => {

  const {user} = UserAuth();
  const navigate = useNavigate();

  const [checked, setChecked] = useState(false);
  const [practiceModulesRadioValue, setPracticeModulesRadioValue] = useState('1');
  const [practiceTestRadioValue, setPracticeTestRadioValue] = useState('1');
  const [activeTestModules, setActiveTestModules] = useState([]);
  const [activeTopicModules, setActiveTopicModules] = useState([]);
  const [passiveTestModules, setPassiveTestModules] = useState([]);
  const [passiveTopicModules, setPassiveTopicModules] = useState([]);
  const [updatedUserFromDB, setUpdatedUserFromDB] = useState({});

  const practiceModulesRadios = [
    { name: 'Active', value: '1' },
    { name: 'Past', value: '2' },
  ];

  const practiceTestRadios = [
    { name: 'Active', value: '1' },
    { name: 'Past', value: '2' },
  ];

  const { isLoading: isLoadingDetailedUser, refetch: refreshCurrentUser, data: detailedUser, isSuccess } = useQuery(
    ["detailed-user", user?.email], () => getDetailedUser(user?.email));

  useEffect(() => {
   
    //this should already be provided in detailedUser from context, but my arrays aren't coming through the context. Maybe a stale state?
    Axios.get('http://localhost:3001/readuser', {
                params: {
                emailAddress: user.email,
            },
            
            }).then((response) => {
                        setUpdatedUserFromDB(response.data[0]);   
                    });

    


  }, [user])

    useEffect(() => {

    const tempPracticeModules = updatedUserFromDB.practiceModules;
    let tempActivePracticeModules = [];
    let tempPassivePracticeModules = [];
    
    if (tempPracticeModules) {
      if (tempPracticeModules[0]) {
 
        tempActivePracticeModules = tempPracticeModules.filter(element => element[0][0]=== "MockAssigned") ;

        tempPassivePracticeModules = tempPracticeModules.filter(element => element[0][0]=== "MockCompleted");

        setActiveTestModules(tempActivePracticeModules.filter(element => element[0][1]=== "Test Review"));

        setActiveTopicModules(tempActivePracticeModules.filter(element => element[0][1]=== "Topic Review"));
        setPassiveTestModules(tempPassivePracticeModules.filter(element => element[0][1]=== "Test Review"));

        setPassiveTopicModules(tempPassivePracticeModules.filter(element => element[0][1]=== "Topic Review"));

      }
    }
     
    // setActiveModulesArray(updatedUserFromDB.practiceModules.filter(element => element[0][0]="MockAssigned"));

  }, [updatedUserFromDB])

  const generateTestsJSX = () => {
    if (practiceTestRadioValue === '1'){
      return (
        <Card.Body>
                    <Card.Title><h4>You Have No Upcoming Tests</h4></Card.Title>
                    <hr></hr>
                    
                    <Card.Text>
                      <h5>The next SAT is Saturday, November 5th.</h5> <br></br> 
  
                      <h5>The next ACT is Saturday, December 10th.</h5> <br></br>
  
                      <h6>Register today.</h6> 
                    </Card.Text>
                  </Card.Body>
      )
    }
    if (practiceTestRadioValue === '2'){

      const tempSATTests = updatedUserFromDB.tests.filter(obj => {
        return obj.testResource === "Official SAT"
      })

      const tempACTTests = updatedUserFromDB.tests.filter(obj => {
        return obj.testResource === "Official ACT"
      })

      return (
        <Card.Body>
                    <Card.Title><h4>You Have Completed the Following Tests</h4></Card.Title>
                    <hr></hr>
                    
                    <Card.Text>
                      <h6 className='font-weight-bold'>SAT Tests</h6>
                      <Table>
                        <thead>                      
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Name</th>
                            <th>Overall</th>
                            <th>Verbal</th>
                            <th>Math</th>
                          </tr>
                        </thead>
                        <tbody>
                         
                          {tempSATTests.map((test,index)=> 
                            <tr>
                              <td>{index+1}</td>
                              <td><Moment format="MM/DD/YY">{test.testDate}</Moment></td>
                              <td>{test.testName}</td>
                              <td>{Number(test.satVerbalScore) + Number(test.satMathScore)}</td>
                              <td>{test.satVerbalScore}</td>
                              <td>{test.satMathScore}</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    
                      <br></br>
                      <h6 className='font-weight-bold'>ACT Tests</h6>
                      <Table>
                        <thead>                      
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Name</th>
                            <th>Overall</th>
                            <th>E</th>
                            <th>M</th>
                            <th>R</th>
                            <th>S</th>
                          </tr>
                        </thead>
                        <tbody>
                         
                          {tempACTTests.map((test,index)=> 
                            <tr>
                              <td>{index+1}</td>
                              <td><Moment format="MM/DD/YY">{test.testDate}</Moment></td>
                              <td>{test.testName}</td>
                              <td>
                                {Math.round((Number(test.actEnglishScore) + Number(test.actMathScore)+
                                Number(test.actReadingScore) + Number(test.actScienceScore))/4)}
                              </td>
                              <td>{test.actEnglishScore}</td>
                              <td>{test.actMathScore}</td>
                              <td>{test.actReadingScore}</td>
                              <td>{test.actScienceScore}</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Card.Text>
                  </Card.Body>
      )
    }
  }

  const generatePracticeModulesJSX = () => {
    if (practiceModulesRadioValue === '1'){
      return (
        <Card.Body>
                    <Card.Title><h4>You have {activeTestModules.length} modules assigned.</h4></Card.Title>
                    <hr></hr>
                    
                    <Card.Text>
                      <ul>

                      
                     {activeTestModules.map((module, index)=>
                      <li key={index*13^4} onClick={(event)=> launchModule(event, index)}
                      className="active-practice-module-welcome-screen">
                        {module[0][1]}: {module[0][2]}
                      </li>
                     )}
                     </ul>
                    </Card.Text>
                  </Card.Body>
      )
    }
    if (practiceModulesRadioValue === '2'){
      return (
        <Card.Body>
                    <Card.Title><h4>You Have Completed {passiveTestModules.length} Modules</h4></Card.Title>
                    <hr></hr>
                    
                    <Card.Text>
                      <ul>
                        {passiveTestModules.map((module, index)=>
                        <li key={index*13^4}>{module[0][1]}: {module[0][2]}</li>
                        )}
                      </ul>
                    </Card.Text>
                  </Card.Body>
      )
    }
  }

  const launchModule = (event, index) =>
  {
    const tempArrayOfQuestions = activeTestModules[index];
    event.preventDefault();
    navigate("/module", {
      state:{
          tempArrayOfQuestions,
          updatedUserFromDB
        },
    });
  }


  return (
    <div className='main-welcome-screen ms-2 pb-5'>
      <Row className="welcome-header-row d-flex align-items-center align-self-center justify-content-center w-100">
        <Col md={"auto"} className='justify-content-center d-flex align-items-center align-self-center justify-content-center ms-auto me-auto'>
            <Card className='welcome-message-card ps-5 pe-5 pt-2 pb-2 add-even-shadow ms-auto me-auto'>
              <Row>
                <Col md="auto" className="ms-auto ps-3 pe-0 mt-0 pb-0">
                  {/* <Image src={logo} alt="image" className='logo-img mb-0 pb-0'/> */}
                </Col>
              
                <Col>
                  <h1> Welcome, {detailedUser.firstName ? detailedUser.firstName : user.email}! </h1>
                </Col>
            

            <h3 className="generic-welcome-message pt-3">Get ready for test day by taking a practice module or full practice test.</h3>
              
            </Row>
              </Card>        

          

          
          
        </Col>
      </Row>
      <Row className='cheap-spacer-row'></Row>

      <div className="main-welcome-container">

    
        <Container className='ps-0 pt-3 scrollable-page'>
          <Row className='pt-5 pb-5'>

            <Col md={8}>
            <Card className='d-flex justify-content-center align-items-center p-3 practice-test-card'>
              <Row className='w-100'>
                


                
                <Col className="pe-5">

                  <h3 className='pt-2 card-heading-white'>
                    Your Practice Tests
                  </h3>
                </Col>

                <Col className='d-flex justify-content-end'>

                    <ButtonGroup className="mb-2 pt-1 official-test-button-group">
                      {practiceTestRadios.map((radio, idx) => (
                        <ToggleButton
                          key={idx}
                          id={`radio1-${idx}`}
                          type="radio"
                          variant="light"
                          name="practice-test-radio"
                          value={radio.value}
                          checked={practiceTestRadioValue === radio.value}
                          onChange={(e) => setPracticeTestRadioValue(e.currentTarget.value)}
                        >
                          {radio.name}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>

                  </Col>

              
              </Row>
              <Row className='w-100'>
                  <Col>
                  <Card className="tests-bootstrap-card">
                    {generateTestsJSX()}

                  </Card>
                
                </Col>
              </Row>
              </Card>
            </Col>
            
            <Col md={4}>
             <div> 
                      <hr className="welcome-hr pt-2 m-0"/>
                      <h1 className='text-primary-color ms-4'>Test</h1>
                      <hr className="welcome-hr p-0 m-0"/>
                      <h4 className='m-3'>Whether you're trying to identify areas of weakness or just learning the test format, nothing beats a full practice test to prepare for test day.</h4>
                      
                      <Button variant="secondary" className='m-2 btn-lg d-flex justify-content-end text-right ms-auto mt-4'> 
                        Test now!
                      </Button>
                      
             </div>
            </Col>
         
          </Row>
          <hr></hr>
          <Row className='pt-5 pb-5'>
            <Col md={4}>
              <div> 
                  <hr className="welcome-hr pt-2 m-0"/>
                  <h1 className='text-primary-color ms-4'>Tutor</h1>
                  <hr className="welcome-hr p-0 m-0"/>
                  <h4 className='m-5'>If you know what you need to learn, don't waste time on another test. Learn that content today!</h4>
                  <Button variant="secondary" className='m-2 btn-lg d-flex justify-content-end text-right me-auto mt-4'> 
                       Learn now!
                      </Button>
               </div>
            </Col>
            <Col md={8}>
              <Card className='d-flex justify-content-center align-items-center p-3 practice-test-card'>
                <Row>
                  
               
                  <Col md={"auto"} className="pe-5">

                    <h3 className='pt-2 card-heading-white'>Your Topics</h3>
                  </Col>

                  <Col>

                      <ButtonGroup className="mb-2 pt-1 official-test-button-group">
                        {practiceTestRadios.map((radio, idx) => (
                          <ToggleButton
                            key={idx}
                            id={`radio1-${idx}`}
                            type="radio"
                            variant="light"
                            name="practice-test-radio"
                            value={radio.value}
                            checked={practiceTestRadioValue === radio.value}
                            onChange={(e) => setPracticeTestRadioValue(e.currentTarget.value)}
                          >
                            {radio.name}
                          </ToggleButton>
                        ))}
                      </ButtonGroup>

                    </Col>

                
                </Row>
                <Row>
                    <Col>
                      <Card className="tests-bootstrap-card">
                        {generateTestsJSX()}

                      </Card>
                    </Col>
                </Row>
              </Card>
                  
            </Col>
          </Row>
          <hr></hr>
          <Row className='pt-5 pb-5'>
            <Col md={8}>
              <Card className='d-flex justify-content-center align-items-center p-3 practice-test-card'>
                <Row className='w-100'>
                  <Col className="pe-5">
                    <h3 className='pt-2 card-heading-white'>
                      Your Practice Modules
                    </h3>
                  </Col>

                  <Col className='d-flex justify-content-end'>

                    <ButtonGroup className="mb-2 pt-1 official-test-button-group">
                      {practiceModulesRadios.map((radio, idx) => (
                        <ToggleButton
                          key={idx}
                          id={`radio2-${idx}`}
                          type="radio"
                          variant="light"
                          name="practice-module-radio"
                          value={radio.value}
                          checked={practiceModulesRadioValue === radio.value}
                          onChange={(e) => setPracticeModulesRadioValue(e.currentTarget.value)}
                        >
                          {radio.name}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </Col>
                </Row>
                <Row className='w-100'>
                  <Col>
                    <Card className="tests-bootstrap-card">
                      {generatePracticeModulesJSX()}
                    </Card>
            
                  </Col>
                </Row>
                </Card>
              </Col>
            
              <Col md={4}>
                <div> 
                <hr className="welcome-hr pt-2 m-0"/>
                  <h1 className='text-primary-color ms-4'>Practice</h1>
                  <hr className="welcome-hr p-0 m-0"/>
                    <h4 className='m-5'>Focused Practice is core of any successful endeavor. Learning isn't the goal. Mastery is.</h4>
                    <Button variant="secondary" className='m-2 btn-lg d-flex justify-content-end text-right ms-auto mt-4'> 
                        Practice now!
                      </Button>
                </div>
              </Col>

          </Row>

          
          
        </Container>
      
      </div>
    </div>
  )
}

export default Welcome